import React from "react";
import {
  Box,
  Typography,
  Grid,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { ActivityCard } from "./ActivityCard";
import { determinePerformanceLevel, getGradientForScore } from "./formatters";
import { ReadinessCard } from "./ReadinessCard";
import { InsightDataItem } from "../AdminDashboard/utils/types";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ReadinessDetailsCardProps {
  readinessInsights: {
    overallScore: number;
    overallState: string;
    heartRateVariability: InsightDataItem | null;
    sleepDebt: InsightDataItem | null;
    mentalRecovery: InsightDataItem | null;
    physicalRecovery: InsightDataItem | null;
    averageRestingHeartRate: number;
  };
  open: boolean;
  onClose: () => void;
}

export const ReadinessDetailsCard: React.FC<ReadinessDetailsCardProps> = ({
  readinessInsights,
  open,
  onClose,
}) => {
  const overallScorePerformance = determinePerformanceLevel(
    readinessInsights?.overallScore ?? 0,
    1
  );

  const heartRateVariabilityPerformance = determinePerformanceLevel(
    readinessInsights.heartRateVariability?.value ?? 0,
    readinessInsights.heartRateVariability?.goal ?? 0
  );

  const mentalRecoveryPerformance = determinePerformanceLevel(
    readinessInsights.mentalRecovery?.value ?? 0,
    readinessInsights.mentalRecovery?.goal ?? 0
  );

  const physicalRecoveryPerformance = determinePerformanceLevel(
    readinessInsights.physicalRecovery?.value ?? 0,
    readinessInsights.physicalRecovery?.goal ?? 0
  );

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          pt: 6,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
            Readiness Details
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle2" sx={{ color: "text.secondary", mt: 1 }}>
          Here&apos;s your daily readiness summary:
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={6}>
            <ActivityCard
              title="Overall Readiness Score"
              value={readinessInsights.overallScore}
              target={1}
              unit=""
              gradient={getGradientForScore(overallScorePerformance)}
              overrideText={readinessInsights.overallState}
            />
          </Grid>
          <Grid item xs={6}>
            <ReadinessCard
              averageRestingHeartRate={
                readinessInsights.averageRestingHeartRate ?? 0
              }
            />
          </Grid>
          <Grid item xs={6}>
            <ActivityCard
              title="HR Variability"
              value={readinessInsights.heartRateVariability?.value ?? 0}
              target={readinessInsights.heartRateVariability?.goal ?? 0}
              gradient={getGradientForScore(heartRateVariabilityPerformance)}
              overrideText={readinessInsights.heartRateVariability?.state ?? ""}
            />
          </Grid>
          <Grid item xs={6}>
            <ActivityCard
              title="Sleep Debt"
              value={readinessInsights.sleepDebt?.value ?? 0}
              target={readinessInsights.sleepDebt?.goal ?? 0}
              unit={readinessInsights.sleepDebt?.unit ?? ""}
              gradient={getGradientForScore("poor")}
            />
          </Grid>
          <Grid item xs={6}>
            <ActivityCard
              title="Mental Recovery"
              value={readinessInsights.mentalRecovery?.value ?? 0}
              target={readinessInsights.mentalRecovery?.goal ?? 0}
              unit={readinessInsights.mentalRecovery?.unit ?? ""}
              gradient={getGradientForScore(mentalRecoveryPerformance)}
            />
          </Grid>
          <Grid item xs={6}>
            <ActivityCard
              title="Physical Recovery"
              value={readinessInsights.physicalRecovery?.value ?? 0}
              target={readinessInsights.physicalRecovery?.goal ?? 0}
              unit={readinessInsights.physicalRecovery?.unit ?? ""}
              gradient={getGradientForScore(physicalRecoveryPerformance)}
            />
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
