import { Capacitor } from "@capacitor/core";
import {
  Sahha,
  SahhaEnvironment,
  SahhaSensor,
  SahhaSensorStatus,
} from "sahha-capacitor";

const STANDARD_SENSORS = [
  SahhaSensor.sleep,
  SahhaSensor.exercise,
  SahhaSensor.steps,
  SahhaSensor.floors_climbed,
  SahhaSensor.active_energy_burned,
  SahhaSensor.heart_rate,
  SahhaSensor.resting_heart_rate,
];

const IOS_REQUIRED_SENSORS = [
  SahhaSensor.stand_time,
  SahhaSensor.move_time,
  SahhaSensor.exercise_time,
  SahhaSensor.activity_summary,
  SahhaSensor.heart_rate_variability_sdnn,
];

const ANDROID_REQUIRED_SENSORS = [SahhaSensor.heart_rate_variability_rmssd];

class SahhaService {
  private static instance: SahhaService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getInstance(): SahhaService {
    if (!SahhaService.instance) {
      SahhaService.instance = new SahhaService();
    }
    return SahhaService.instance;
  }

  // eslint-disable-next-line class-methods-use-this, consistent-return
  async configureSahha(): Promise<void | Error> {
    try {
      await Sahha.configure({
        settings: {
          environment: SahhaEnvironment.production,
        },
      });
      console.log("Sahha configured successfully.");
    } catch (error) {
      console.error("Error configuring Sahha:", error);
      return new Error("Failed to configure Sahha");
    }
  }

  /**
   * Initializes Sahha, checks authentication, logs in if necessary, and handles permissions.
   */
  async initSahha(profileToken: string, refreshToken: string) {
    try {
      const authStatus = await Sahha.isAuthenticated();
      if (!authStatus.success) {
        await this.login(profileToken, refreshToken);
      } else {
        console.log("Sahha already authenticated");
      }
    } catch (error) {
      console.error("Error initializing Sahha:", error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public async login(profileToken: string, refreshToken: string) {
    try {
      const response = await Sahha.authenticateToken({
        profileToken,
        refreshToken,
      });
      console.log("Sahha authentication response:", response);
      return response.success;
    } catch (error) {
      console.error("Error during Sahha authentication:", error);
      return false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private async getRequiredSensors() {
    const platform = Capacitor.getPlatform();
    if (platform === "ios") {
      return [...STANDARD_SENSORS, ...IOS_REQUIRED_SENSORS];
    }
    return [...STANDARD_SENSORS, ...ANDROID_REQUIRED_SENSORS];
  }

  async getSahhaSensorStatus(): Promise<string> {
    try {
      const REQUIRED_SENSORS = await this.getRequiredSensors();
      const sensorStatus = await Sahha.getSensorStatus({
        sensors: REQUIRED_SENSORS,
      });
      console.log("Sensor Status:", SahhaSensorStatus[sensorStatus.status]);
      if (sensorStatus.status === SahhaSensorStatus.enabled) {
        return "enabled";
      }
      if (sensorStatus.status === SahhaSensorStatus.disabled) {
        return "disabled";
      }
      if (sensorStatus.status === SahhaSensorStatus.unavailable) {
        return "unavailable";
      }
      return "pending";
    } catch (error) {
      console.error("Error getting sensor status:", error);
      return "unavailable";
    }
  }

  async enableSahhaSensors(): Promise<boolean> {
    try {
      const REQUIRED_SENSORS = await this.getRequiredSensors();
      const enableResponse = await Sahha.enableSensors({
        sensors: REQUIRED_SENSORS,
      });
      return enableResponse.status === SahhaSensorStatus.enabled;
    } catch (error) {
      console.error("Error enabling Sahha sensors:", error);
      return false;
    }
  }

  /**
   * Opens the app settings.
   */
  static async openAppSettings() {
    try {
      await Sahha.openAppSettings();
    } catch (error) {
      console.error("Error opening app settings:", error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async getStepStats() {
    try {
      const date = new Date();

      const stats = await Sahha.getStats({
        sensor: SahhaSensor.steps,
        startDateTime: date.getTime(),
        endDateTime: date.getTime(),
      });

      if (!stats?.value) {
        return { value: 0 };
      }

      try {
        const jsonArray = JSON.parse(stats.value);

        if (Array.isArray(jsonArray) && jsonArray.length > 0) {
          const steps = jsonArray[jsonArray.length - 1].value || 0;
          return { value: Math.floor(steps) || 0 };
        }
      } catch (parseError) {
        console.error(`Error: ${parseError}`);
      }

      return { value: 0 };
    } catch (error) {
      console.error(`Error: ${error}`);
      return { value: 0 };
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async getActiveCaloriesStats() {
    try {
      const date = new Date();

      const stats = await Sahha.getStats({
        sensor: SahhaSensor.active_energy_burned,
        startDateTime: date.getTime(),
        endDateTime: date.getTime(),
      });

      if (!stats?.value) {
        return { value: 0 };
      }

      try {
        const jsonArray = JSON.parse(stats.value);

        if (Array.isArray(jsonArray) && jsonArray.length > 0) {
          const activeCalories = jsonArray[jsonArray.length - 1].value || 0;
          return { value: Math.floor(activeCalories) || 0 };
        }
      } catch (parseError) {
        console.error(`Error: ${parseError}`);
      }

      return { value: 0 };
    } catch (error) {
      console.error(`Error: ${error}`);
      return { value: 0 };
    }
  }
}

export default SahhaService;
