import React from "react";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import { Box, Typography, Grid } from "@mui/material";
import { UserLeaderboardResponse } from "shared/api";
import { Colors, getGraphicSvg } from "shared/themes";

interface LeaderboardScoreCardProps {
  leaderboardData: UserLeaderboardResponse | undefined;
  open?: boolean;
  onClose?: () => void;
  isFullScreen?: boolean;
}

export const LeaderboardScoreCard: React.FC<LeaderboardScoreCardProps> = ({
  leaderboardData,
  open,
  onClose,
  isFullScreen,
}) => {
  const dailyScores = leaderboardData?.dailyScores || [];

  const chartData = {
    series: [
      {
        name: "Score",
        data: [...dailyScores].reverse().map((score) => score.value),
      },
    ],
    xaxis: {
      categories: [...dailyScores]
        .reverse()
        .map((score) => dayjs(score.date).format("dd").charAt(0)),
    },
  };

  const totalScore = leaderboardData?.totalScore || 0;

  const startOfMonth = dayjs().startOf("month").format("MMM D");
  const currentDay = dayjs().format("MMM D");

  return (
    <Box
      sx={{
        padding: "16px",
        backgroundColor: "#fff",
        borderRadius: "12px",
        textAlign: "left",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          cursor: "pointer",
        },
      }}
    >
      {totalScore === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            height: "100%",
          }}
        >
          <Box
            component="img"
            src={getGraphicSvg("gameworld")}
            alt="Motivation"
            sx={{ height: "100px", mb: 2 }}
          />
          <Typography variant="h6" sx={{ color: Colors.gray[800], mb: 1 }}>
            Get Started!
          </Typography>
          <Typography
            sx={{
              fontSize: "1rem",
              color: "rgba(0, 0, 0, 0.6)",
              mb: 1,
            }}
          >
            You can earn points by doing activities like workouts, walking, and
            more! Data is updated daily.
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              sx={{ fontSize: "0.9rem", color: "rgba(0, 0, 0, 0.6)" }}
            >
              Monthly score
            </Typography>
            <Typography
              sx={{
                fontSize: "1.7rem",
                fontWeight: "bold",
                color: "#333",
                mt: 1,
              }}
            >
              {totalScore.toLocaleString()}
            </Typography>
            <Typography
              sx={{
                fontSize: "0.9rem",
                color: "rgba(0, 0, 0, 0.6)",
                mt: 0.5,
              }}
            >
              {leaderboardData?.totalScores?.workouts?.toLocaleString()}{" "}
              workouts
            </Typography>
            {leaderboardData?.totalScores?.steps ? (
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  color: "rgba(0, 0, 0, 0.6)",
                  mt: 0.5,
                }}
              >
                {leaderboardData?.totalScores?.steps?.toLocaleString()} steps
              </Typography>
            ) : null}
            <Typography
              sx={{
                fontSize: "0.6rem",
                color: "rgba(0, 0, 0, 0.6)",
                mt: 0.5,
              }}
            >
              Data from {startOfMonth} to {currentDay}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Chart
                options={{
                  chart: {
                    id: "basic-bar",
                    toolbar: {
                      show: false,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      borderRadius: 7,
                      borderRadiusApplication: "end",
                      columnWidth: "70%",
                    },
                  },
                  grid: {
                    show: false,
                  },
                  fill: {
                    colors: ["#4245FF", "#2F32FF", "#7A7DFF"],
                  },
                  yaxis: {
                    labels: {
                      show: false,
                    },
                  },
                  xaxis: {
                    ...chartData.xaxis,
                    tickAmount: 7,
                    labels: {
                      show: true,
                      style: {
                        fontSize: "12px",
                        fontFamily: "Inter",
                        fontWeight: 400,
                        cssClass: "apexcharts-xaxis-label",
                      },
                    },
                  },
                }}
                series={chartData.series}
                type="bar"
                width="100%"
                height="120"
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
