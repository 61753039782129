/* eslint-disable react/jsx-no-duplicate-props */
import { FC, useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  UserProfileItemResponse,
  useUpdateUserProfileMutation,
} from "shared/api";
import { Colors } from "shared/themes";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { LoadingButton } from "@mui/lab";

interface ProfileFormProps {
  currentUserData?: UserProfileItemResponse;
  onSuccess?: () => void;
}

interface ProfileFormValues {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
}

interface FormFieldProps {
  label: string;
  value: string;
  name: keyof ProfileFormValues;
  placeholder?: string;
  type?: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur: (e: React.FocusEvent<any>) => void;
  error?: boolean;
  disabled?: boolean;
}

const FormField: FC<FormFieldProps> = ({
  label,
  value,
  name,
  placeholder = "",
  type = "text",
  onChange,
  onBlur,
  error,
  disabled = false,
}) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      py: 2,
      px: 3,
      borderBottom: `1px solid ${Colors.gray[200]}`,
      bgcolor: "white",
    }}
  >
    <Typography
      sx={{
        color: Colors.gray[900],
        fontSize: "17px",
        fontWeight: 400,
      }}
    >
      {label}
    </Typography>
    <TextField
      variant="standard"
      value={value}
      name={name}
      placeholder={placeholder}
      type={type}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      disabled={disabled}
      inputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
      }}
      InputProps={{
        disableUnderline: true,
        sx: {
          textAlign: "right",
          "& input": {
            textAlign: "right",
            color: disabled ? Colors.gray[600] : "black",
            fontWeight: disabled ? 400 : 600,
            fontSize: "17px",
            "&::placeholder": {
              color: Colors.gray[400],
              opacity: 1,
            },
          },
        },
      }}
    />
  </Box>
);

const ProfileSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  phoneNumber: Yup.string(),
});

const ProfileForm: FC<ProfileFormProps> = ({ currentUserData, onSuccess }) => {
  const [updateUserProfile, { isLoading }] = useUpdateUserProfileMutation();
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async (values: ProfileFormValues) => {
    setErrorMessage("");
    try {
      await updateUserProfile(values).unwrap();
      if (onSuccess) {
        onSuccess();
      }
    } catch (error: any) {
      setErrorMessage(error?.data?.message || "Unable to update profile");
    }
  };

  const { values, handleSubmit, handleChange, handleBlur, errors, touched } =
    useFormik<ProfileFormValues>({
      initialValues: {
        id: currentUserData?.id,
        firstName: currentUserData?.firstName || "",
        lastName: currentUserData?.lastName || "",
        phoneNumber: currentUserData?.phoneNumber || "",
        email: currentUserData?.email || "",
      },
      validationSchema: ProfileSchema,
      onSubmit,
      enableReinitialize: true,
    });

  return (
    <Box sx={{ height: "100%", bgcolor: Colors.gray[100] }}>
      {/* Profile Photo Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: 4,
        }}
      >
        <Box sx={{ position: "relative" }}>
          <IdenticonAvatar
            sizeValue={100}
            seedValue={currentUserData?.id?.toString()}
            profileImageUrl={currentUserData?.profileImageUrl}
            allowUpload
          />
        </Box>
      </Box>

      {/* Form Fields */}
      <Box>
        <FormField
          label="First name"
          value={values.firstName}
          name="firstName"
          placeholder="Enter first name"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.firstName && Boolean(errors.firstName)}
          disabled
        />
        <FormField
          label="Last name"
          value={values.lastName}
          name="lastName"
          placeholder="Enter last name"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.lastName && Boolean(errors.lastName)}
          disabled
        />
        <FormField
          label="Mobile"
          value={values.phoneNumber || ""}
          name="phoneNumber"
          placeholder="enter your mobile phone"
          type="tel"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.phoneNumber && Boolean(errors.phoneNumber)}
        />
        <FormField
          label="Email"
          value={values.email}
          name="email"
          type="email"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && Boolean(errors.email)}
          disabled
        />
      </Box>

      {/* Save Button */}
      <Box sx={{ p: 2, mt: 4 }}>
        <LoadingButton
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            height: "42px",
          }}
          type="submit"
          size="large"
          loading={isLoading}
          onClick={() => handleSubmit()}
          disabled={isLoading}
        >
          <Typography
            sx={{
              textTransform: "none",
            }}
          >
            Save
          </Typography>
        </LoadingButton>
      </Box>

      {errorMessage && (
        <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

export { ProfileForm };
