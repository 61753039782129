import { Box, TextField, Typography } from "@mui/material";
import { FC } from "react";
import { CombinedData } from "shared/api";
import { Colors } from "shared/themes";

type DefaultInputScreenWithPartialsProps = {
  values: any;
  handleChange: any;
  handleBlur: any;
  exercise: CombinedData;
};
// eslint-disable-next-line
const DefaultInputScreenWithPartials: FC<
  DefaultInputScreenWithPartialsProps
> = ({ values, handleChange, handleBlur, exercise }) => {
  return (
    <Box
      sx={{
        width: "100%",
        gap: "4px",
        flex: "none",
        order: 1,
        flexGrow: 0,
        borderRadius: "8px",
        background: "white",
        p: 1.5,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          mb: 2,
          "&::before, &::after": {
            content: '""',
            flex: 1,
            borderBottom: `1px solid ${Colors.oxford[1100]}`,
            opacity: 0.12,
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            letterSpacing: "0.1em",
            textTransform: "uppercase",
            px: 2,
          }}
        >
          YOUR SET
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px",
          mb: "12px",
          gap: "12px",
          flex: "none",
          order: 1,
          flexGrow: 0,
        }}
      >
        <TextField
          sx={{
            width: "50%",
            height: "40px",
            background: "white",
            "& input[type=number]": {
              "-moz-appearance": "textfield",
              height: "40px",
              padding: "8px 14px",
              fontSize: "14px",
            },
            "& input": {
              height: "40px",
              padding: "8px 14px",
              fontSize: "14px",
            },
            "& .MuiOutlinedInput-root": {
              height: "40px",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              transform: "translate(14px, 8px) scale(1)",
              "&.Mui-focused, &.MuiFormLabel-filled": {
                transform: "translate(14px, -9px) scale(0.75)",
              },
            },
          }}
          type="number"
          inputProps={{
            step: "any",
            inputMode: "decimal",
          }}
          value={values.completedWeight}
          label={
            exercise.workoutItemWeightUnit === "Lbs"
              ? "Weight (Lbs)"
              : "Weight (Kg)"
          }
          onBlur={handleBlur("completedWeight")}
          onChange={handleChange("completedWeight")}
        />
        <TextField
          sx={{
            width: "50%",
            height: "40px",
            background: "white",
            "& input[type=number]": {
              "-moz-appearance": "textfield",
              height: "40px",
              padding: "8px 14px",
              fontSize: "14px",
            },
            "& input": {
              height: "40px",
              padding: "8px 14px",
              fontSize: "14px",
            },
            "& .MuiOutlinedInput-root": {
              height: "40px",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              transform: "translate(14px, 8px) scale(1)",
              "&.Mui-focused, &.MuiFormLabel-filled": {
                transform: "translate(14px, -9px) scale(0.75)",
              },
            },
          }}
          type="number"
          inputProps={{
            step: "any",
            inputMode: "decimal",
          }}
          value={values.completedPartialReps}
          label="Partial reps"
          onBlur={handleBlur("completedPartialReps")}
          onChange={handleChange("completedPartialReps")}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px",
          gap: "12px",
          flex: "none",
          order: 1,
          flexGrow: 0,
        }}
      >
        <TextField
          sx={{
            width: "50%",
            height: "40px",
            background: "white",
            "& input[type=number]": {
              "-moz-appearance": "textfield",
              height: "40px",
              padding: "8px 14px",
              fontSize: "14px",
            },
            "& input": {
              height: "40px",
              padding: "8px 14px",
              fontSize: "14px",
            },
            "& .MuiOutlinedInput-root": {
              height: "40px",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              transform: "translate(14px, 8px) scale(1)",
              "&.Mui-focused, &.MuiFormLabel-filled": {
                transform: "translate(14px, -9px) scale(0.75)",
              },
            },
          }}
          type="number"
          inputProps={{
            step: "any",
            inputMode: "decimal",
          }}
          value={values.completedReps}
          label="Reps"
          onBlur={handleBlur("completedReps")}
          onChange={handleChange("completedReps")}
        />
        <TextField
          sx={{
            width: "50%",
            height: "40px",
            background: "white",
            "& input[type=number]": {
              "-moz-appearance": "textfield",
              height: "40px",
              padding: "8px 14px",
              fontSize: "14px",
            },
            "& input": {
              height: "40px",
              padding: "8px 14px",
              fontSize: "14px",
            },
            "& .MuiOutlinedInput-root": {
              height: "40px",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              transform: "translate(14px, 8px) scale(1)",
              "&.Mui-focused, &.MuiFormLabel-filled": {
                transform: "translate(14px, -9px) scale(0.75)",
              },
            },
          }}
          type="number"
          inputProps={{
            step: "any",
            inputMode: "decimal",
          }}
          value={values.completedIntensity}
          label={exercise.workoutItemType === "RIR" ? "RIR" : "RPE"}
          onBlur={handleBlur("completedIntensity")}
          onChange={handleChange("completedIntensity")}
        />
      </Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <hr
          style={{
            border: "none",
            height: "1px",
            background: `${Colors.gray[500]}`,
            width: "100%",
          }}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: "0px",
          mt: "12px",
        }}
      >
        <TextField
          sx={{
            width: "100%",
            height: "40px",
            background: "white",
            "& input[type=number]": {
              "-moz-appearance": "textfield",
              height: "40px",
              padding: "8px 14px",
              fontSize: "14px",
            },
            "& input": {
              height: "40px",
              padding: "8px 14px",
              fontSize: "14px",
            },
            "& .MuiOutlinedInput-root": {
              height: "40px",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              transform: "translate(14px, 8px) scale(1)",
              "&.Mui-focused, &.MuiFormLabel-filled": {
                transform: "translate(14px, -9px) scale(0.75)",
              },
            },
          }}
          value={values.notes}
          label="Notes"
          onBlur={handleBlur("notes")}
          onChange={handleChange("notes")}
        />
      </Box>
    </Box>
  );
};

export { DefaultInputScreenWithPartials };
