import React, { FC } from "react";
import dayjs from "dayjs";
import {
  Box,
  Typography,
  Skeleton,
  Paper,
  Grid,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import {
  useGetAthleteLeaderboardQuery,
  useGetActivePartnerQuery,
  useAddPartnerTrackingLogMutation,
} from "shared/api/healthScores/healthScores";
import { PartnerTrackingLogRequest } from "shared/api";
import { Colors, getGraphicSvg } from "shared/themes";
import { styled } from "@mui/system";
import { SafeAreaBox } from "components/SafeAreaBox/SafeAreaBox";
import { LeaderboardScoreCard } from "../Dashboard/AthleteDashboard/LeaderboardScoreCard";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LeaderboardContainer = styled(Box)({
  padding: "24px",
});

const StatsCard = styled(Paper)({
  backgroundColor: "white",
  border: `1px solid ${Colors.gray[200]}`,
  borderRadius: "16px",
  padding: "24px",
  marginBottom: "16px",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
  },
});

const CombinedCard = styled(StatsCard)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "24px",
  height: "auto",
});

interface AthleteLeaderboardProps {
  open: boolean;
  onClose: () => void;
}

export const AthleteLeaderboard: FC<AthleteLeaderboardProps> = ({
  open,
  onClose,
}) => {
  const { data: leaderboardData, isLoading } = useGetAthleteLeaderboardQuery(
    {}
  );
  const { data: partnerData } = useGetActivePartnerQuery({});
  const [addPartnerTrackingLog] = useAddPartnerTrackingLogMutation();

  const handleLearnMoreClick = () => {
    try {
      addPartnerTrackingLog({
        partnerId: partnerData?.id,
        partnerTrackingLogType: "click",
      } as PartnerTrackingLogRequest).unwrap();
    } catch {
      console.log("Unable to track partner");
    }

    window.open(partnerData?.url, "_blank");
  };

  const leaderboardPrizeIsEnabled = () => {
    return partnerData?.name && partnerData?.prizeIsActive;
  };

  if (isLoading) {
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: "relative",
            background: `linear-gradient(
              126.67deg,
              #8B44AD 0%,
              #6C5CE7 50.52%,
              #4834D4 100%
            )`,
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
              Leaderboard
            </Typography>
          </Toolbar>
        </AppBar>
        <LeaderboardContainer>
          <Skeleton variant="text" width={200} height={32} />
          <Skeleton variant="text" width={300} height={24} />
          <Skeleton variant="rectangular" height={400} sx={{ mt: 3 }} />
        </LeaderboardContainer>
      </Dialog>
    );
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          pt: 6,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
            Leaderboard
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ height: "100%", overflowY: "auto" }}>
        <LeaderboardContainer>
          <Box sx={{ mb: 4 }}>
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "24px",
                lineHeight: "100%",
                color: Colors.blue[1500],
              }}
            >
              {dayjs().format("MMMM YYYY")}
            </Typography>
          </Box>

          <Box sx={{ textAlign: "left", mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LeaderboardScoreCard
                  leaderboardData={leaderboardData}
                  isFullScreen
                />
              </Grid>
            </Grid>
          </Box>

          {leaderboardPrizeIsEnabled() ? (
            <>
              {leaderboardData?.rank &&
                leaderboardData.rank !== 0 &&
                leaderboardData.rank < 5 && (
                  <Box
                    sx={{
                      backgroundColor: Colors.blue[200],
                      padding: "20px",
                      borderRadius: "10px",
                      mt: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      textAlign: "left",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                      Keep it up!
                    </Typography>
                    <Typography variant="body1">
                      You are among the top 5 athletes in the running to win a
                      prize this month.
                    </Typography>
                  </Box>
                )}

              <Grid mt={2}>
                {partnerData?.name && (
                  <Grid item xs={12}>
                    <CombinedCard
                      elevation={0}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <Box sx={{ flexGrow: 1, textAlign: "left" }}>
                          <Typography
                            variant="h6"
                            sx={{
                              color: Colors.gray[900],
                              mb: 1,
                              fontWeight: "600",
                            }}
                          >
                            {partnerData?.prize}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: Colors.gray[600],
                              mb: 1,
                            }}
                          >
                            Provided by {partnerData?.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: Colors.gray[800],
                            }}
                          >
                            {partnerData?.description}
                          </Typography>
                        </Box>
                        <Box
                          component="img"
                          src={partnerData?.logoUrl}
                          alt="Partner Logo"
                          sx={{ height: "80px", objectFit: "contain", ml: 2 }}
                        />
                      </Box>
                      <Box
                        sx={{
                          mt: 1,
                          textAlign: "left",
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: Colors.blue[600],
                            cursor: "pointer",
                          }}
                          onClick={handleLearnMoreClick}
                        >
                          Learn more about {partnerData?.name}
                        </Typography>
                      </Box>
                    </CombinedCard>
                  </Grid>
                )}
              </Grid>
            </>
          ) : null}
        </LeaderboardContainer>
      </Box>
    </Dialog>
  );
};
