import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetCompletedHistoryByExerciseIdQuery } from "shared/api";
import { Alert, Box, Typography, Skeleton } from "@mui/material";
import { sharedStyles } from "shared/themes/shared/styles";
import { Colors } from "shared/themes";
import { XAxisType, YAxisType } from "./TrainingInsightsLineChart";
import { SeriesType } from "./constants";
import {
  TrainingInsightsMultiAxisLineChart,
  YMultiAxisType,
} from "./TrainingInsightsMultiAxisLineChart";
import { TrainingInsightsTableForAverages } from "./TrainingInsightsTableForAverages";
import { SelectedExercise } from "./TrainingInsightsContent";

interface TrainingInsightsLineChartContainerForAveragesProps {
  userId: number;
  exercise: SelectedExercise;
  isAthleteView?: boolean;
}

type CompletedDataItem = {
  completedReps: number;
  completedPartialReps?: number;
  completedPartialRepsLeftSide?: number;
  completedPartialRepsRightSide?: number;
  completedRepsLeftSide?: number;
  completedRepsRightSide?: number;
  maxCompletedWeight: number;
  maxCompletedWeightLeftSide?: number;
  maxCompletedWeightRightSide?: number;
};

const TrainingInsightsLineChartContainerForAverages: FC<
  TrainingInsightsLineChartContainerForAveragesProps
> = ({ userId, exercise, isAthleteView = false }) => {
  const { t } = useTranslation();

  const [xAxis, setXAxis] = useState<XAxisType>({ labels: [] });
  const [yAxisCompleted, setYAxisCompleted] = useState<YMultiAxisType[]>([]);
  const [isCalculating, setIsCalculating] = useState(false);

  const {
    data: completedData,
    isLoading: isLoadingCompleted,
    error,
  } = useGetCompletedHistoryByExerciseIdQuery({
    userId: userId ?? 0,
    exerciseId: exercise.exerciseId ?? 0,
  });

  useEffect(() => {
    if (completedData) {
      setIsCalculating(true);
      try {
        setXAxis({
          labels: completedData.map(
            (a) => `${a.workoutProgram} - Week ${a.weekNumber}`
          ),
        });

        const yAxisDataWeight: YAxisType[] = [];
        const yAxisDataReps: YAxisType[] = [];
        const yAxisDataPartialReps: YAxisType[] = [];

        const addYAxisData = (
          property: keyof CompletedDataItem,
          label: string,
          yAxisType:
            | "AverageRepsCompleted"
            | "AveragePartialRepsCompleted"
            | "AverageWeightCompleted"
        ) => {
          if (completedData.some((a) => a[property])) {
            const isRIRStrategy = completedData.some((a) =>
              a[property]?.toString().includes("-")
            );
            const data = completedData.map((a) => {
              if (isRIRStrategy) {
                const maxCompletedReps = a[property]
                  .replace(/[^0-9-.]/g, "")
                  .split("-")
                  .map((a) => (a === "" ? "0" : a))
                  .map(Number);
                return (
                  maxCompletedReps.reduce((a: number, b: number) => a + b, 0) /
                  maxCompletedReps.length
                );
              }

              return Number(a[property].replace(/[^0-9-.]/g, "")) ?? 0;
            });

            if (yAxisType === "AverageWeightCompleted") {
              yAxisDataWeight.push({
                values: data,
                name: label,
              });
            } else if (yAxisType === "AverageRepsCompleted") {
              yAxisDataReps.push({
                values: data,
                name: label,
              });
            } else if (yAxisType === "AveragePartialRepsCompleted") {
              yAxisDataPartialReps.push({
                values: data,
                name: label,
              });
            }
          }
        };

        if (completedData.some((a) => a.completedReps)) {
          addYAxisData(
            "completedReps",
            SeriesType.CompletedReps,
            "AverageRepsCompleted"
          );
        }
        if (completedData.some((a) => a.completedRepsLeftSide)) {
          addYAxisData(
            "completedRepsLeftSide",
            SeriesType.CompletedRepsLeftSide,
            "AverageRepsCompleted"
          );
        }
        if (completedData.some((a) => a.completedRepsRightSide)) {
          addYAxisData(
            "completedRepsRightSide",
            SeriesType.CompletedRepsRightSide,
            "AverageRepsCompleted"
          );
        }
        if (completedData.some((a) => a.completedPartialReps)) {
          addYAxisData(
            "completedPartialReps",
            SeriesType.CompletedPartialReps,
            "AveragePartialRepsCompleted"
          );
        }
        if (completedData.some((a) => a.completedPartialRepsLeftSide)) {
          addYAxisData(
            "completedPartialRepsLeftSide",
            SeriesType.CompletedPartialRepsLeftSide,
            "AveragePartialRepsCompleted"
          );
        }
        if (completedData.some((a) => a.completedPartialRepsRightSide)) {
          addYAxisData(
            "completedPartialRepsRightSide",
            SeriesType.CompletedPartialRepsRightSide,
            "AveragePartialRepsCompleted"
          );
        }

        if (completedData.some((a) => a.maxCompletedWeight)) {
          addYAxisData(
            "maxCompletedWeight",
            SeriesType.CompletedWeight,
            "AverageWeightCompleted"
          );
        }
        if (completedData.some((a) => a.maxCompletedWeightLeftSide)) {
          addYAxisData(
            "maxCompletedWeightLeftSide",
            SeriesType.CompletedWeightLeftSide,
            "AverageWeightCompleted"
          );
        }
        if (completedData.some((a) => a.maxCompletedWeightRightSide)) {
          addYAxisData(
            "maxCompletedWeightRightSide",
            SeriesType.CompletedWeightRightSide,
            "AverageWeightCompleted"
          );
        }

        const yAxisDataAveraged: YMultiAxisType[] = [];
        const averagedReps =
          yAxisDataReps.length > 0
            ? yAxisDataReps.reduce((a, b) => {
                return {
                  values: a.values.map((v, i) => {
                    const average = (v + b.values[i]) / 2;
                    return v !== 0 && b.values[i] !== 0
                      ? average
                      : Math.max(v, b.values[i]);
                  }),
                  name: "",
                };
              })
            : ({
                values: [],
                name: "",
              } as YAxisType);

        const averagedPartialReps =
          yAxisDataPartialReps.length > 0
            ? yAxisDataPartialReps.reduce((a, b) => {
                return {
                  values: a.values.map((v, i) => {
                    const average = (v + b.values[i]) / 2;
                    return v !== 0 && b.values[i] !== 0
                      ? average
                      : Math.max(v, b.values[i]);
                  }),
                  name: "",
                };
              })
            : ({
                values: [],
                name: "",
              } as YAxisType);

        const averagedWeight =
          yAxisDataWeight.length > 0
            ? yAxisDataWeight.reduce((a, b) => {
                return {
                  values: a.values.map((v, i) => {
                    const average = (v + b.values[i]) / 2;
                    return v !== 0 && b.values[i] !== 0
                      ? average
                      : Math.max(v, b.values[i]);
                  }),
                  name: "",
                };
              })
            : ({
                values: [],
                name: "",
              } as YAxisType);

        if (averagedReps?.values?.length > 0) {
          yAxisDataAveraged.push({
            values: averagedReps.values,
            name: SeriesType.AverageRepsCompleted,
            type: "y",
          });
        }
        if (averagedPartialReps?.values?.length > 0) {
          yAxisDataAveraged.push({
            values: averagedPartialReps.values,
            name: SeriesType.AveragePartialRepsCompleted,
            type: "y",
          });
        }

        if (averagedWeight?.values?.length > 0) {
          yAxisDataAveraged.push({
            values: averagedWeight.values,
            name: SeriesType.AverageWeightCompleted,
            type: "y1",
          });
        }

        setYAxisCompleted(yAxisDataAveraged);
      } finally {
        setIsCalculating(false);
      }
    }
  }, [completedData]);

  const displayGraph =
    !isLoadingCompleted &&
    !isCalculating &&
    xAxis?.labels.length > 0 &&
    yAxisCompleted.length > 0;

  const displayNoData =
    !isLoadingCompleted && !isCalculating && completedData?.length === 0;

  const renderContent = () => {
    if (isLoadingCompleted || isCalculating) {
      return (
        <Box sx={{ width: "100%" }}>
          <Skeleton
            variant="rectangular"
            height={isAthleteView ? 350 : 400}
            sx={{
              borderRadius: 2,
              bgcolor: Colors.gray[200],
              mb: 2,
            }}
          />
          {!isAthleteView && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Skeleton
                variant="rectangular"
                height={50}
                sx={{
                  borderRadius: 1,
                  bgcolor: Colors.gray[200],
                }}
              />
              <Skeleton
                variant="rectangular"
                height={200}
                sx={{
                  borderRadius: 1,
                  bgcolor: Colors.gray[200],
                }}
              />
            </Box>
          )}
        </Box>
      );
    }

    if (displayNoData || !displayGraph) {
      return (
        <Alert
          variant="outlined"
          severity="info"
          sx={{ mb: "10px", alignItems: "center" }}
        >
          <Typography sx={sharedStyles.body.timeline.cardBody}>
            No data available for {exercise.exerciseName}. Complete some
            workouts to see your progress!
          </Typography>
        </Alert>
      );
    }

    if (displayGraph && !error) {
      if (!isAthleteView) {
        return (
          <Box
            sx={{
              gap: 3,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                maxHeight: "65vh",
              }}
            >
              <TrainingInsightsMultiAxisLineChart
                title={`${exercise.exerciseName ?? ""} - Overview`}
                xAxis={xAxis}
                yAxisSeries={yAxisCompleted}
              />
            </Box>
            <TrainingInsightsTableForAverages
              completedData={completedData ?? []}
              yAxisSeries={yAxisCompleted}
            />
          </Box>
        );
      }
      return (
        <Box
          sx={{
            height: "350px",
          }}
        >
          <TrainingInsightsMultiAxisLineChart
            title={`${exercise.exerciseName ?? ""} - Overview`}
            xAxis={xAxis}
            yAxisSeries={yAxisCompleted}
            isAthleteView
          />
        </Box>
      );
    }

    return null;
  };

  return (
    <>
      {!isAthleteView && (
        <Alert
          variant="outlined"
          severity="info"
          sx={{ mb: "10px", alignItems: "center" }}
        >
          <Typography sx={sharedStyles.body.timeline.cardBody}>
            {t("training-insights.disclaimer")}
          </Typography>
        </Alert>
      )}
      {error && (
        <Alert
          variant="outlined"
          severity="error"
          sx={{ mb: "10px", alignItems: "center" }}
        >
          <Typography sx={sharedStyles.body.timeline.cardBody}>
            {t("errors.server-unable")}
          </Typography>
        </Alert>
      )}
      {renderContent()}
    </>
  );
};

export { TrainingInsightsLineChartContainerForAverages };
