import { Box } from "@mui/system";
import { Skeleton, Typography } from "@mui/material";
import { Colors } from "shared/themes";

const UserProfileSkeleton = () => {
  return (
    <Box sx={{ height: "100vh", backgroundColor: "white" }}>
      <Box sx={{ height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              flex: 1,
              overflowY: "auto",
            }}
          >
            {/* Settings Title */}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                p: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 500,
                  color: Colors.gray[900],
                }}
              >
                Settings
              </Typography>
            </Box>

            {/* List Items */}
            <Box>
              {/* Profile Information */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 2,
                  borderBottom: `1px solid ${Colors.gray[200]}`,
                }}
              >
                <Skeleton width={120} height={24} />
                <Skeleton width={24} height={24} />
              </Box>

              {/* App Settings */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 2,
                  borderBottom: `1px solid ${Colors.gray[200]}`,
                }}
              >
                <Skeleton width={100} height={24} />
                <Skeleton width={24} height={24} />
              </Box>

              {/* Health Permissions */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 2,
                  borderBottom: `1px solid ${Colors.gray[200]}`,
                }}
              >
                <Skeleton width={140} height={24} />
                <Skeleton width={24} height={24} />
              </Box>
            </Box>

            {/* Logout Button */}
            <Box sx={{ p: 2, mt: "auto" }}>
              <Skeleton
                variant="rectangular"
                height={40}
                sx={{
                  borderRadius: 1,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { UserProfileSkeleton };
