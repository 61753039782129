import {
  Box,
  Icon,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { FC } from "react";
import { Colors } from "shared/themes";

type WorkoutPreviousWeekDataProps = {
  previousWeeksData?: string[];
  previousWeeksNotes?: string[];
  exerciseName: string;
};

const WorkoutPreviousWeekData: FC<WorkoutPreviousWeekDataProps> = ({
  previousWeeksData,
  previousWeeksNotes = [],
  exerciseName,
}) => {
  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: "14px",
          lineHeight: "20px",
          color: Colors.oxford[1100],
          mb: 1,
          fontWeight: 600,
        }}
      >
        Last session for {exerciseName}
      </Typography>
      {previousWeeksData && previousWeeksData.length > 0 && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {previousWeeksData.map((item) => (
            <Typography
              key={item}
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                lineHeight: "20px",
                color: Colors.oxford[1100],
              }}
            >
              {item}
            </Typography>
          ))}
        </Box>
      )}

      {previousWeeksNotes && previousWeeksNotes.length > 0 && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 1 }}>
          {previousWeeksNotes.map((item) => (
            <Typography
              key={item}
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                lineHeight: "20px",
                color: Colors.oxford[1100],
              }}
            >
              {item}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
};

export { WorkoutPreviousWeekData };
