import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

export const SafeAreaBox = styled(Box)(() => ({
  paddingTop: "env(safe-area-inset-top)",
  paddingBottom: "env(safe-area-inset-bottom)",
  paddingLeft: "env(safe-area-inset-left)",
  paddingRight: "env(safe-area-inset-right)",
  minHeight: "100vh", // Optional to ensure full viewport
}));
