import { Box, Typography } from "@mui/material";
import { FC } from "react";
import ReactPlayer from "react-player/lazy";

interface WorkoutExerciseVideoProps {
  videoUrl: string;
  exerciseName: string;
}

export const WorkoutExerciseVideo: FC<WorkoutExerciseVideoProps> = ({
  videoUrl,
  exerciseName,
}) => {
  return (
    <Box
      sx={{
        borderRadius: "12px",
        overflow: "hidden", // This ensures the video respects the border radius
      }}
    >
      <Typography variant="h6">{exerciseName}</Typography>
      <ReactPlayer
        url={videoUrl}
        controls
        width="100%"
        height="auto"
        pip
        light
        style={{ aspectRatio: "16/9" }}
      />
    </Box>
  );
};
