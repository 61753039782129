import {
  Button,
  Icon,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Routes } from "../../../../shared/routers";
import { Colors } from "../../../../shared/themes";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SubmitFeedbackProps {
  linkTo?: string;
}

const SubmitFeedback: FC<SubmitFeedbackProps> = ({
  linkTo = Routes.dashboard.url,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog fullScreen open TransitionComponent={Transition}>
      <AppBar
        sx={{
          position: "relative",
          pt: 7,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
        }}
      >
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
            Feedback Submitted
          </Typography>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "56px",
            height: "56px",
            borderRadius: "50px",
            background: "rgba(76, 175, 80, 0.08)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <Box
            sx={{
              width: "32px",
              height: "32px",
              borderRadius: "50px",
              bgcolor: Colors.green[500],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon sx={{ color: Colors.gray[100] }}>check_icon</Icon>
          </Box>
        </Box>

        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "140%",
            textAlign: "center",
            color: Colors.gray[1200],
            mb: 3,
          }}
        >
          {t("feedback.thanks")}
        </Typography>

        <Button
          component={NavLink}
          to={linkTo}
          reloadDocument
          sx={{
            width: "100%",
            height: "42px",
            color: Colors.blue[1200],
            textTransform: "none",
          }}
        >
          {t("feedback.back")}
        </Button>
      </Box>
    </Dialog>
  );
};

export { SubmitFeedback };
