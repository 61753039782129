import { FC, useState } from "react";
import { Box } from "@mui/system";
import { Badge, Card, CardContent, Typography } from "@mui/material";
import { Colors } from "shared/themes";
import { Timeline } from "shared/api";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import MailIcon from "@mui/icons-material/Mail";
import { AthleteTimelineView } from "./AthleteTimelineView";

interface AthleteTimelineCardProps {
  timeline: Timeline;
}

const AthleteTimelineCard: FC<AthleteTimelineCardProps> = ({ timeline }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const seedValue =
    timeline.timelineType === "Private" && timeline.userIds.length > 0
      ? timeline.userIds[0].toString()
      : "";

  const timelineName =
    timeline.timelineType === "Private" ? "My Timeline" : timeline.name;

  const handleClick = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Card
        onClick={handleClick}
        sx={{
          width: "100%",
          position: "relative",
          height: "120px",
          border: "1px solid #EEEEEE",
          borderRadius: "8px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.02)",
          transition: "all 0.2s ease-in-out",
          backgroundColor: "white",
          cursor: "pointer",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: "0 4px 8px rgba(0,0,0,0.05)",
          },
        }}
      >
        <CardContent
          sx={{
            height: "100%",
            padding: "16px !important",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
            <IdenticonAvatar
              seedValue={seedValue}
              profileImageUrl={timeline.profileImageUrl}
              sizeValue={40}
            />
            <Box sx={{ flex: 1 }}>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: timeline.unreadCount > 0 ? 600 : 500,
                  fontSize: "14px",
                  lineHeight: "1.2",
                  color: Colors.oxford[1100],
                  mb: 0.5,
                }}
              >
                {timelineName}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  color: Colors.gray[700],
                  lineHeight: "1.2",
                }}
              >
                {timeline.timelineType} Timeline
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
              borderTop: `1px solid ${Colors.gray[200]}`,
              pt: 1,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "11px",
                color: Colors.gray[600],
              }}
            >
              {timeline.mostRecentTimelineItemDate
                ? `Last updated: ${new Date(
                    timeline.mostRecentTimelineItemDate
                  ).toLocaleDateString()}`
                : "No updates yet"}
            </Typography>

            {timeline.unreadCount > 0 && (
              <Badge
                badgeContent={timeline.unreadCount}
                color="warning"
                sx={{
                  "& .MuiBadge-badge": {
                    fontSize: "10px",
                    height: "16px",
                    minWidth: "16px",
                    padding: "0 4px",
                  },
                }}
              >
                <MailIcon
                  sx={{
                    color: Colors.gray[600],
                    fontSize: "18px",
                  }}
                />
              </Badge>
            )}
          </Box>
        </CardContent>
      </Card>

      <AthleteTimelineView
        open={isDialogOpen}
        onClose={handleClose}
        timelineId={timeline.id}
      />
    </>
  );
};

export { AthleteTimelineCard };
