import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  Drawer,
  FormControlLabel,
  FormGroup,
  Icon,
  IconButton,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  Slide,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CombinedData,
  WorkoutLoadStatus,
  useChangeWorkoutItemLogMutation,
  useGetWorkoutItemLogQuery,
  useGetUserProfileItemQuery,
} from "shared/api";
import { Colors } from "shared/themes";
import { userSelectors } from "shared/stores/user";
import { useTypedSelector } from "shared/stores";
import { SnackbarAlert } from "components/SnackbarAlert/SnackbarAlert";
import { TrainingInsightsLineChartContainerForAverages } from "pages/TrainingInsights/TrainingInsightsLineChartContainerForAverages";
import { TransitionProps } from "@mui/material/transitions";
import { SwapExerciseModal } from "./SwapExerciseModal";
import RestTimer from "./RestTimer";
import { ChangeWorkoutDetailsModalInputCard } from "./ChangeWorkoutDetailsModalInputCard";
import { WorkoutPreviousWeekData } from "./WorkoutPreviousWeekData";
import { WorkoutExerciseVideo } from "./WorkoutExerciseVideo";
import { WorkoutTrendGraph } from "./WorkoutTrendGraph";

interface ChangeWorkoutDetailsModalProps {
  isOpenModal: boolean;
  closeModal: () => void;
  exercise: CombinedData;
  exerciseName: string;
  selectedUserId: number;
  setExerciseName: (value: string) => void;
}
interface SubmitValues {
  id: number;
  completedReps: string;
  completedPartialReps: string;
  completedIntensity: string;
  completedWeight: string;
  completedWeightRightSide: string;
  completedWeightLeftSide: string;
  completedRepsRightSide: string;
  completedRepsLeftSide: string;
  completedPartialRepsRightSide: string;
  completedPartialRepsLeftSide: string;
  completedIntensityRightSide: string;
  completedIntensityLeftSide: string;
  completedDistance: string;
  completedTime: string;
  completedSpeed: string;
  actualRestTime: string;
  setOrder: number | null;
  notes: string;
  workoutItemId: number;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChangeWorkoutDetailsModal: FC<ChangeWorkoutDetailsModalProps> = ({
  isOpenModal,
  closeModal,
  exercise,
  exerciseName,
  setExerciseName,
  selectedUserId,
}) => {
  const { t } = useTranslation();

  const [changeWorkoutItemLog, { isLoading }] =
    useChangeWorkoutItemLogMutation();

  const [swapExerciseModal, setSwapExerciseModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const isAdminOrCoach = useTypedSelector(userSelectors.isAdminOrCoach);

  const [isResponseModalOpen, setResponseModal] = useState(false);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setResponseModal(false);
  };

  const [selectedWorkoutItemId, setSelectedWorkoutItemId] = useState<number>(
    exercise.workoutItemSchemeBreakdowns.find((a) => true)?.workoutItemId ?? 0
  );

  const [selectedWorkoutItemLogId, setSelectedWorkoutItemLogId] =
    useState<number>(
      exercise.workoutItemSchemeBreakdowns.find((a) => true)?.workoutItemLogs[0]
        .id ?? 0
    );

  const { data: workoutItemLog } = useGetWorkoutItemLogQuery(
    selectedWorkoutItemLogId
  );

  const selectedWorkoutItem = exercise.workoutItemSchemeBreakdowns?.find(
    (item) => item.workoutItemId === selectedWorkoutItemId
  );
  const handleSelectedWorkoutItemIdChange = (
    newWorkoutItemId: number,
    id: number
  ) => {
    setSuccessMessage("");
    setErrorMessage("");
    setSelectedWorkoutItemId(newWorkoutItemId);
    setSelectedWorkoutItemLogId(id);
  };

  const onApplyToAllSetsSubmit = async ({
    completedIntensity,
    completedWeight,
    completedReps,
    completedPartialReps,
    completedIntensityLeftSide,
    completedIntensityRightSide,
    completedPartialRepsLeftSide,
    completedPartialRepsRightSide,
    completedRepsLeftSide,
    completedRepsRightSide,
    completedWeightLeftSide,
    completedWeightRightSide,
    actualRestTime,
    completedDistance,
    completedTime,
    completedSpeed,
    notes,
  }: SubmitValues) => {
    setErrorMessage("");
    setSuccessMessage("");
    try {
      const workoutItemSchema = exercise.workoutItemSchemeBreakdowns?.find(
        (item) =>
          item.workoutItemLogs.find(
            (log) => log.id === selectedWorkoutItemLogId
          )
      );
      workoutItemSchema?.workoutItemLogs.forEach(async (log) => {
        await changeWorkoutItemLog({
          completedReps: completedReps.toString(),
          completedPartialReps: completedPartialReps.toString(),
          completedIntensity: completedIntensity.toString(),
          completedWeight: completedWeight.toString(),
          completedWeightRightSide: completedWeightRightSide.toString(),
          completedWeightLeftSide: completedWeightLeftSide.toString(),
          completedRepsRightSide: completedRepsRightSide.toString(),
          completedRepsLeftSide: completedRepsLeftSide.toString(),
          completedPartialRepsRightSide:
            completedPartialRepsRightSide.toString(),
          completedPartialRepsLeftSide: completedPartialRepsLeftSide.toString(),
          completedIntensityRightSide: completedIntensityRightSide.toString(),
          completedIntensityLeftSide: completedIntensityLeftSide.toString(),
          actualRestTime: actualRestTime.toString(),
          completedDistance: completedDistance.toString(),
          completedTime: completedTime.toString(),
          completedSpeed: completedSpeed.toString(),
          notes,
          id: log.id,
        }).unwrap();
      });
      setSuccessMessage(t("item-modal.finish-title"));
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setResponseModal(true);
    }
  };

  const onSubmit = async ({
    completedIntensity,
    completedWeight,
    completedReps,
    completedPartialReps,
    completedIntensityLeftSide,
    completedIntensityRightSide,
    completedPartialRepsLeftSide,
    completedPartialRepsRightSide,
    completedRepsLeftSide,
    completedRepsRightSide,
    completedWeightLeftSide,
    completedWeightRightSide,
    actualRestTime,
    completedDistance,
    completedTime,
    completedSpeed,
    notes,
  }: SubmitValues) => {
    setErrorMessage("");
    setSuccessMessage("");
    try {
      await changeWorkoutItemLog({
        completedReps: completedReps.toString(),
        completedPartialReps: completedPartialReps.toString(),
        completedIntensity: completedIntensity.toString(),
        completedWeight: completedWeight.toString(),
        completedWeightRightSide: completedWeightRightSide.toString(),
        completedWeightLeftSide: completedWeightLeftSide.toString(),
        completedRepsRightSide: completedRepsRightSide.toString(),
        completedRepsLeftSide: completedRepsLeftSide.toString(),
        completedPartialRepsRightSide: completedPartialRepsRightSide.toString(),
        completedPartialRepsLeftSide: completedPartialRepsLeftSide.toString(),
        completedIntensityRightSide: completedIntensityRightSide.toString(),
        completedIntensityLeftSide: completedIntensityLeftSide.toString(),
        actualRestTime: actualRestTime.toString(),
        completedDistance: completedDistance.toString(),
        completedTime: completedTime.toString(),
        completedSpeed: completedSpeed.toString(),
        notes,
        id: selectedWorkoutItemLogId,
      }).unwrap();
      setSuccessMessage(t("item-modal.finish-title"));
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setResponseModal(true);
    }
  };

  const { values, handleSubmit, handleChange, handleBlur, errors, resetForm } =
    useFormik({
      initialValues: {
        id: workoutItemLog?.id || 0,
        completedReps: workoutItemLog?.completedReps || "",
        completedPartialReps: workoutItemLog?.completedPartialReps || "",
        completedIntensity: workoutItemLog?.completedIntensity || "",
        completedWeight: workoutItemLog?.completedWeight || "",
        completedWeightRightSide:
          workoutItemLog?.completedWeightRightSide || "",
        completedWeightLeftSide: workoutItemLog?.completedWeightLeftSide || "",
        completedRepsRightSide: workoutItemLog?.completedRepsRightSide || "",
        completedRepsLeftSide: workoutItemLog?.completedRepsLeftSide || "",
        completedPartialRepsRightSide:
          workoutItemLog?.completedPartialRepsRightSide || "",
        completedPartialRepsLeftSide:
          workoutItemLog?.completedPartialRepsLeftSide || "",
        completedIntensityRightSide:
          workoutItemLog?.completedIntensityRightSide || "",
        completedIntensityLeftSide:
          workoutItemLog?.completedIntensityLeftSide || "",
        actualRestTime: workoutItemLog?.actualRestTime || "",
        setOrder: workoutItemLog?.setOrder || null,
        workoutItemId: workoutItemLog?.workoutItemId || 0,
        completedDistance: workoutItemLog?.completedDistance || "",
        completedTime: workoutItemLog?.completedTime || "",
        completedSpeed: workoutItemLog?.completedSpeed || "",
        notes: workoutItemLog?.notes || "",
      },
      onSubmit: async (params) => {
        await onSubmit(params);
      },
    });

  useEffect(() => {
    if (workoutItemLog) {
      resetForm({
        values: {
          id: workoutItemLog?.id || 0,
          completedReps: workoutItemLog?.completedReps || "",
          completedPartialReps: workoutItemLog?.completedPartialReps || "",
          completedIntensity: workoutItemLog?.completedIntensity || "",
          completedWeight: workoutItemLog?.completedWeight || "",
          completedWeightRightSide:
            workoutItemLog?.completedWeightRightSide || "",
          completedWeightLeftSide:
            workoutItemLog?.completedWeightLeftSide || "",
          completedRepsRightSide: workoutItemLog?.completedRepsRightSide || "",
          completedRepsLeftSide: workoutItemLog?.completedRepsLeftSide || "",
          completedPartialRepsRightSide:
            workoutItemLog?.completedPartialRepsRightSide || "",
          completedPartialRepsLeftSide:
            workoutItemLog?.completedPartialRepsLeftSide || "",
          completedIntensityRightSide:
            workoutItemLog?.completedIntensityRightSide || "",
          completedIntensityLeftSide:
            workoutItemLog?.completedIntensityLeftSide || "",
          actualRestTime: workoutItemLog?.actualRestTime || "",
          setOrder: workoutItemLog?.setOrder || null,
          workoutItemId: workoutItemLog?.workoutItemId || 0,
          completedDistance: workoutItemLog?.completedDistance || "",
          completedTime: workoutItemLog?.completedTime || "",
          completedSpeed: workoutItemLog?.completedSpeed || "",
          notes: workoutItemLog?.notes || "",
        },
      });
    }
  }, [workoutItemLog, resetForm]);

  const displayTrendTable = useMediaQuery("(min-width:700px)");

  const [showTrendGraph, setShowTrendGraph] = useState(false);

  const handleClickOpenTrend = () => {
    setShowTrendGraph(true);
  };

  const handleCloseTrend = () => {
    setShowTrendGraph(false);
  };

  const [isTimerOpen, setIsTimerOpen] = useState(false);

  const handleTimerClick = () => {
    setIsTimerOpen(true);
  };

  const handleTimerClose = () => {
    setIsTimerOpen(false);
  };

  const [expandedSchemaId, setExpandedSchemaId] = useState<number | null>(() =>
    exercise.workoutItemSchemeBreakdowns.length === 1
      ? exercise.workoutItemSchemeBreakdowns[0].workoutItemId
      : null
  );

  const [showPreviousSessionDialog, setShowPreviousSessionDialog] =
    useState(false);
  const [showVideoDialog, setShowVideoDialog] = useState(false);

  return (
    <Dialog
      fullScreen
      open={isOpenModal}
      onClose={closeModal}
      TransitionComponent={Transition}
    >
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          pt: 8,
          pb: 3,
          pl: 3,
          pr: 3,
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {/* Return button and title */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Button
              type="button"
              onClick={closeModal}
              sx={{
                minWidth: "auto",
                padding: 0,
                color: "primary.main",
                "&:hover": {
                  background: "none",
                },
                textTransform: "none",
              }}
            >
              <Icon
                sx={{
                  fontSize: "16px !important",
                  width: "16px",
                  height: "16px",
                }}
              >
                arrow_back_ios
              </Icon>
              <Typography
                sx={{
                  fontSize: "14px",
                  ml: 0.5,
                  color: "primary.main",
                  fontWeight: 500,
                }}
              >
                Return
              </Typography>
            </Button>
          </Box>

          {/* Exercise title and action buttons */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography
              variant="h5"
              sx={{
                fontSize: "24px",
                fontWeight: 600,
                color: Colors.oxford[1100],
                flex: 1,
              }}
            >
              {exerciseName}
            </Typography>

            <Box sx={{ display: "flex" }}>
              {exercise.previousWeeksData &&
                exercise.previousWeeksData.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      sx={{
                        minWidth: "auto",
                        p: 1,
                        borderRadius: 1,
                      }}
                      onClick={() => setShowPreviousSessionDialog(true)}
                    >
                      <Icon
                        sx={{
                          color: Colors.blue[1200],
                          fontSize: 20,
                        }}
                      >
                        history
                      </Icon>
                    </IconButton>
                    <Typography
                      sx={{
                        fontSize: "10px",
                        color: Colors.blue[1200],
                        mt: -0.5,
                        userSelect: "none",
                      }}
                    >
                      Last Session
                    </Typography>
                  </Box>
                )}
              {exercise.videoUrl && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    sx={{
                      minWidth: "auto",
                      p: 1,
                      borderRadius: 1,
                    }}
                    onClick={() => setShowVideoDialog(true)}
                  >
                    <Icon
                      sx={{
                        color: Colors.blue[1200],
                        fontSize: 20,
                      }}
                    >
                      play_circle
                    </Icon>
                  </IconButton>
                  <Typography
                    sx={{
                      fontSize: "10px",
                      color: Colors.blue[1200],
                      mt: -0.5,
                      userSelect: "none",
                    }}
                  >
                    Video
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>

          {/* Action buttons */}
          <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
            <Button
              variant="outlined"
              sx={{
                borderRadius: 2,
                backgroundColor: Colors.blue[100],
                borderColor: "transparent",
                color: "primary.main",
                flex: 1,
                justifyContent: "flex-start",
                padding: "8px 16px",
                height: "40px",
                "&:hover": {
                  borderColor: "transparent",
                  backgroundColor: Colors.blue[100],
                },
                textTransform: "none",
              }}
              onClick={() => setSwapExerciseModal(!swapExerciseModal)}
              startIcon={
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "6px",
                    width: 28,
                    height: 28,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <Icon sx={{ fontSize: 20, color: "primary.main" }}>
                    sync_alt
                  </Icon>
                </Box>
              }
            >
              Swap
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderRadius: 2,
                backgroundColor: Colors.blue[100],
                borderColor: "transparent",
                color: "primary.main",
                flex: 1,
                justifyContent: "flex-start",
                padding: "8px 16px",
                height: "40px",
                "&:hover": {
                  borderColor: "transparent",
                  backgroundColor: Colors.blue[100],
                },
                textTransform: "none",
              }}
              onClick={handleClickOpenTrend}
              startIcon={
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "6px",
                    width: 28,
                    height: 28,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <Icon sx={{ fontSize: 20, color: "primary.main" }}>
                    stacked_bar_chart
                  </Icon>
                </Box>
              }
            >
              History
            </Button>
          </Box>
        </Box>

        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "0px",
              width: "100%",
              flex: "none",
              order: 1,
              flexGrow: 0,
            }}
          >
            {exercise.notes && (
              <Box
                sx={{
                  backgroundColor: "#FFF7DE",
                  padding: "12px 16px",
                  mt: 2,
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Icon
                    className="material-icons-outlined"
                    sx={{
                      color: "#896804",
                      opacity: 0.6,
                      fontSize: 20,
                    }}
                  >
                    mark_chat_unread
                  </Icon>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#896804",
                      opacity: 0.6,
                    }}
                  >
                    Coach&apos;s note
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: Colors.oxford[1100],
                  }}
                >
                  {exercise.notes}
                </Typography>
              </Box>
            )}

            <SwapExerciseModal
              swapExerciseModal={swapExerciseModal}
              setSwapExerciseModal={setSwapExerciseModal}
              exercise={exercise}
              workoutItemId={selectedWorkoutItemId}
              exerciseName={exerciseName}
              setExerciseName={setExerciseName}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "0px",
              width: "100%",
              flex: "none",
              order: 1,
              flexGrow: 0,
              pt: 2,
            }}
          >
            {exercise.workoutItemSchemeBreakdowns.map((schema) => (
              <ChangeWorkoutDetailsModalInputCard
                key={schema.workoutItemId}
                schema={schema}
                selectedWorkoutItemId={selectedWorkoutItemId}
                selectedWorkoutItemLogId={selectedWorkoutItemLogId}
                onSelect={handleSelectedWorkoutItemIdChange}
                exerciseType={exercise.exerciseType}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                exercise={exercise}
                handleTimerClick={handleTimerClick}
                handleSubmit={handleSubmit}
                onApplyToAllSetsSubmit={onApplyToAllSetsSubmit}
                isLoading={isLoading}
                isExpanded={expandedSchemaId === schema.workoutItemId}
                onExpandChange={(expanded) => {
                  setExpandedSchemaId(expanded ? schema.workoutItemId : null);
                }}
              />
            ))}
          </Box>

          <WorkoutTrendGraph
            open={showTrendGraph}
            onClose={handleCloseTrend}
            exerciseId={exercise.exerciseId}
            exerciseName={exerciseName}
            userId={selectedUserId!!}
            isAthleteView={!displayTrendTable}
          />
          {/* Timer Dialog */}
          <RestTimer
            open={isTimerOpen}
            onClose={handleTimerClose}
            existingRestTime={selectedWorkoutItem?.restTime}
          />
        </Box>
      </Box>
      {errorMessage && (
        <SnackbarAlert
          isOpen={isResponseModalOpen}
          onClose={handleClose}
          severity="error"
          message={errorMessage}
        />
      )}
      {successMessage && (
        <SnackbarAlert
          isOpen={isResponseModalOpen}
          onClose={handleClose}
          severity="success"
          message={successMessage}
        />
      )}

      <Dialog
        fullScreen
        open={showPreviousSessionDialog}
        onClose={() => setShowPreviousSessionDialog(false)}
        TransitionComponent={Transition}
      >
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            pt: 8,
            pb: 3,
            pl: 3,
            pr: 3,
            overflow: "auto",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
            <Button
              type="button"
              onClick={() => setShowPreviousSessionDialog(false)}
              sx={{
                minWidth: "auto",
                padding: 0,
                color: "primary.main",
                "&:hover": {
                  background: "none",
                },
                textTransform: "none",
              }}
            >
              <Icon
                sx={{
                  fontSize: "16px !important",
                  width: "16px",
                  height: "16px",
                }}
              >
                arrow_back_ios
              </Icon>
              <Typography
                sx={{
                  fontSize: "14px",
                  ml: 0.5,
                  color: "primary.main",
                  fontWeight: 500,
                }}
              >
                Return
              </Typography>
            </Button>
          </Box>
          <WorkoutPreviousWeekData
            previousWeeksData={exercise.previousWeeksData}
            previousWeeksNotes={exercise?.previousWeeksNotes ?? []}
            exerciseName={exerciseName}
          />
        </Box>
      </Dialog>

      <Dialog
        fullScreen
        open={showVideoDialog}
        onClose={() => setShowVideoDialog(false)}
        TransitionComponent={Transition}
      >
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            pt: 8,
            pb: 3,
            pl: 3,
            pr: 3,
            overflow: "auto",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
            <Button
              type="button"
              onClick={() => setShowVideoDialog(false)}
              sx={{
                minWidth: "auto",
                padding: 0,
                color: "primary.main",
                "&:hover": {
                  background: "none",
                },
                textTransform: "none",
              }}
            >
              <Icon
                sx={{
                  fontSize: "16px !important",
                  width: "16px",
                  height: "16px",
                }}
              >
                arrow_back_ios
              </Icon>
              <Typography
                sx={{
                  fontSize: "14px",
                  ml: 0.5,
                  color: "primary.main",
                  fontWeight: 500,
                }}
              >
                Return
              </Typography>
            </Button>
          </Box>
          <WorkoutExerciseVideo
            videoUrl={exercise.videoUrl}
            exerciseName={exerciseName}
          />
        </Box>
      </Dialog>
    </Dialog>
  );
};

export { ChangeWorkoutDetailsModal };
