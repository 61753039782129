import { FC } from "react";
import { Box, Typography, Skeleton, Grid, Card } from "@mui/material";
import { useGetTimelinesQuery } from "shared/api";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import { AthleteTimelineCard } from "./AthleteTimelineCard";

const AthleteTimelineSelector: FC = () => {
  const { t } = useTranslation();
  const { data: timelines, isLoading } = useGetTimelinesQuery({});

  if (isLoading) {
    return (
      <Box sx={{ padding: "24px 24px 23px 24px" }}>
        <Box sx={{ mb: 2 }}>
          <Skeleton variant="text" width={140} height={24} />
          <Skeleton variant="text" width={200} height={32} />
        </Box>
        <Skeleton variant="rectangular" height={120} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" height={120} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" height={120} />
      </Box>
    );
  }

  return (
    <Box sx={{ height: "100vh", backgroundColor: Colors.gray[50] }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Card
          sx={{
            width: "100%",
            position: "relative",
            borderRadius: 0,
            background: `linear-gradient(
                126.67deg,
                #8B44AD 0%,
                #6C5CE7 50.52%,
                #4834D4 100%
              )`,
            pt: 8,
            pb: 3,
            pl: 3,
            pr: 3,
          }}
        >
          <Box>
            <Typography
              variant="h5"
              sx={{
                color: Colors.gray[100],
                fontWeight: 600,
                mb: 1,
              }}
            >
              Timelines
            </Typography>
            <Typography
              sx={{
                color: Colors.gray[100],
              }}
            >
              Share your progress, ask questions, and get support from your
              coach
            </Typography>
            <Box sx={{ height: "20px" }} />
          </Box>
        </Card>

        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            padding: "24px",
          }}
        >
          <Grid container spacing={2} direction="column">
            {timelines?.map((timeline) => (
              <Grid item key={timeline.id}>
                <AthleteTimelineCard timeline={timeline} />
              </Grid>
            ))}
          </Grid>

          {timelines?.length === 0 && (
            <Typography
              sx={{
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.6)",
                mt: 4,
              }}
            >
              {t("timeline.no-timelines")}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export { AthleteTimelineSelector };
