import { Box, TextField, Typography } from "@mui/material";
import { FC } from "react";
import { Colors } from "shared/themes";

type DefaultCardioInputScreenProps = {
  values: any;
  handleChange: any;
  handleBlur: any;
};
// eslint-disable-next-line
const DefaultCardioInputScreen: FC<DefaultCardioInputScreenProps> = ({
  values,
  handleChange,
  handleBlur,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        gap: "4px",
        flex: "none",
        order: 1,
        flexGrow: 0,
        borderRadius: "8px",
        background: "white",
        p: 1.5,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          mb: 2,
          "&::before, &::after": {
            content: '""',
            flex: 1,
            borderBottom: `1px solid ${Colors.oxford[1100]}`,
            opacity: 0.12,
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            letterSpacing: "0.1em",
            textTransform: "uppercase",
            px: 2,
          }}
        >
          YOUR SET
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: "0px",
          mb: "12px",
        }}
      >
        <TextField
          sx={{
            width: "100%",
            height: "40px",
            background: "white",
            "& input[type=number]": {
              "-moz-appearance": "textfield",
              height: "40px",
              padding: "8px 14px",
              fontSize: "14px",
            },
            "& input": {
              height: "40px",
              padding: "8px 14px",
              fontSize: "14px",
            },
            "& .MuiOutlinedInput-root": {
              height: "40px",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              transform: "translate(14px, 8px) scale(1)",
              "&.Mui-focused, &.MuiFormLabel-filled": {
                transform: "translate(14px, -9px) scale(0.75)",
              },
            },
          }}
          type="number"
          inputProps={{
            step: "any",
          }}
          value={values.completedDistance}
          label="Distance"
          onBlur={handleBlur("completedDistance")}
          onChange={handleChange("completedDistance")}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px",
          gap: "12px",
          flex: "none",
          order: 1,
          flexGrow: 0,
        }}
      >
        <TextField
          sx={{
            width: "50%",
            height: "40px",
            background: "white",
            "& input[type=number]": {
              "-moz-appearance": "textfield",
              height: "40px",
              padding: "8px 14px",
              fontSize: "14px",
            },
            "& input": {
              height: "40px",
              padding: "8px 14px",
              fontSize: "14px",
            },
            "& .MuiOutlinedInput-root": {
              height: "40px",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              transform: "translate(14px, 8px) scale(1)",
              "&.Mui-focused, &.MuiFormLabel-filled": {
                transform: "translate(14px, -9px) scale(0.75)",
              },
            },
          }}
          type="number"
          inputProps={{
            step: "any",
          }}
          value={values.completedSpeed}
          label="Speed"
          onBlur={handleBlur("completedSpeed")}
          onChange={handleChange("completedSpeed")}
        />
        <TextField
          sx={{
            width: "50%",
            height: "40px",
            background: "white",
            "& input[type=number]": {
              "-moz-appearance": "textfield",
              height: "40px",
              padding: "8px 14px",
              fontSize: "14px",
            },
            "& input": {
              height: "40px",
              padding: "8px 14px",
              fontSize: "14px",
            },
            "& .MuiOutlinedInput-root": {
              height: "40px",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              transform: "translate(14px, 8px) scale(1)",
              "&.Mui-focused, &.MuiFormLabel-filled": {
                transform: "translate(14px, -9px) scale(0.75)",
              },
            },
          }}
          type="number"
          inputProps={{
            step: "any",
          }}
          value={values.completedIntensity}
          label="Intensity (Incline, etc)"
          onBlur={handleBlur("completedIntensity")}
          onChange={handleChange("completedIntensity")}
        />
      </Box>
    </Box>
  );
};

export { DefaultCardioInputScreen };
