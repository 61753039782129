import React, { useState, useEffect } from "react";
import {
  Dialog,
  Box,
  Typography,
  IconButton,
  Button,
  Snackbar,
  Icon,
} from "@mui/material";
import { Colors } from "shared/themes";

interface RestTimerProps {
  open: boolean;
  onClose: () => void;
  existingRestTime?: string;
}

const RestTimer: React.FC<RestTimerProps> = ({
  open,
  onClose,
  existingRestTime = "",
}) => {
  const parseRestTime = (timeStr: string): number => {
    const DEFAULT_SECONDS = 120; // Default to 2 minutes

    if (!timeStr) return DEFAULT_SECONDS;

    // Trim and simplify the input string
    const trimmed = timeStr
      .trim()
      .toLowerCase()
      .replace(/and|,/g, " ")
      .replace(/\s+/g, " ");

    // Parse "MM:SS" format
    const timeParts = trimmed.split(":");
    if (timeParts.length === 2) {
      const [minutes, seconds] = timeParts.map(
        (part) => parseInt(part, 10) || 0
      );
      const totalSeconds = (minutes || 0) * 60 + (seconds || 0);
      return !Number.isNaN(totalSeconds) && totalSeconds > 0
        ? totalSeconds
        : DEFAULT_SECONDS;
    }

    // Match number-unit pairs (minutes, seconds)
    const regex = /(\d+\.?\d*)\s*(m(?:in(?:utes?)?)?|s(?:ec(?:onds?)?)?)?/gi;
    const matches = Array.from(trimmed.matchAll(regex));

    const totalSeconds = matches.reduce((acc, match) => {
      const value = parseFloat(match[1]);
      const unit = (match[2] || "").toLowerCase();

      if (unit.startsWith("m")) return acc + value * 60; // Minutes
      if (unit.startsWith("s") || !unit) return acc + value; // Seconds or no unit
      return acc; // Handle unknown units gracefully
    }, 0);

    // Fallback if a valid match was found and parsed
    if (totalSeconds > 0) return totalSeconds;

    // If no valid units found, check if the entire string is a number
    const parsedNum = parseFloat(trimmed);
    return !Number.isNaN(parsedNum) && parsedNum > 0
      ? parsedNum
      : DEFAULT_SECONDS;
  };

  const [timeLeft, setTimeLeft] = useState<number>(
    parseRestTime(existingRestTime)
  ); // Default or parsed time
  const [timerActive, setTimerActive] = useState<boolean>(false);
  const [timerFinished, setTimerFinished] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [endTime, setEndTime] = useState<number | null>(null); // New state variable for end time

  useEffect(() => {
    const newTime = parseRestTime(existingRestTime);
    setTimeLeft(newTime);
    setTimerActive(false);
    setTimerFinished(false);
    setEndTime(null);
  }, [existingRestTime]);

  useEffect(() => {
    let timerInterval: NodeJS.Timeout | null = null;
    if (timerActive && endTime) {
      timerInterval = setInterval(() => {
        const currentTime = Date.now();
        const newTimeLeft = (endTime - currentTime) / 1000; // in seconds
        if (newTimeLeft <= 0) {
          setTimeLeft(0);
          setTimerActive(false);
          setTimerFinished(true);
          setSnackbarOpen(true);
          setEndTime(null);
        } else {
          setTimeLeft(newTimeLeft);
        }
      }, 1000);
    }
    return () => {
      if (timerInterval) clearInterval(timerInterval);
    };
  }, [timerActive, endTime]);

  const handleMinus30 = () => {
    setTimeLeft((prevTime) => {
      const newTime = prevTime - 30;
      return newTime > 0 ? newTime : 0;
    });
    if (timerActive && endTime) {
      setEndTime((prevEndTime) => (prevEndTime ? prevEndTime - 30000 : null)); // Subtract 30 seconds
    }
  };

  const handlePlus30 = () => {
    setTimeLeft((prevTime) => prevTime + 30);
    if (timerActive && endTime) {
      setEndTime((prevEndTime) => (prevEndTime ? prevEndTime + 30000 : null)); // Add 30 seconds
    }
  };

  const handleStart = () => {
    setTimerActive(true);
    setTimerFinished(false);
    setEndTime(Date.now() + timeLeft * 1000); // Set the end time
  };

  const handlePause = () => {
    if (endTime) {
      const currentTime = Date.now();
      const newTimeLeft = (endTime - currentTime) / 1000;
      setTimeLeft(newTimeLeft > 0 ? newTimeLeft : 0);
    }
    setTimerActive(false);
    setEndTime(null);
  };

  const formatTime = (totalSeconds: number) => {
    const roundedSeconds = Math.ceil(totalSeconds);
    const minutes = Math.floor(roundedSeconds / 60);
    const seconds = roundedSeconds % 60;
    const minutesStr = minutes.toString();
    const secondsStr = seconds < 10 ? `0${seconds}` : seconds.toString();
    return `${minutesStr}:${secondsStr}`;
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDialogClose = () => {
    onClose();
    setTimeLeft(parseRestTime(existingRestTime)); // Reset to default when dialog is closed
    setTimerActive(false);
    setTimerFinished(false);
    setEndTime(null);
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "16px",
          margin: 2,
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          padding: 3,
          backgroundColor: "#fff",
        }}
      >
        {/* Header */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 3 }}>
          <Icon
            className="material-icons-outlined"
            sx={{
              color: Colors.oxford[1100],
              opacity: 0.6,
              fontSize: 20,
            }}
          >
            timer
          </Icon>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: "500",
              fontSize: "14px",
              color: Colors.oxford[1100],
              opacity: 0.6,
            }}
          >
            Rest Timer
          </Typography>
        </Box>

        {/* Timer Display */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            py: 4,
          }}
        >
          {timerFinished ? (
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: 600,
                color: Colors.red[500],
              }}
            >
              Time is up
            </Typography>
          ) : (
            <Typography
              sx={{
                fontSize: "48px",
                fontWeight: 600,
                color: Colors.blue[1200],
              }}
            >
              {formatTime(timeLeft)}
            </Typography>
          )}
        </Box>

        {/* Controls */}
        {!timerFinished && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
              <Button
                variant="outlined"
                onClick={handleMinus30}
                sx={{
                  borderRadius: 2,
                  textTransform: "none",
                  borderColor: Colors.blue[1200],
                  color: Colors.blue[1200],
                  "&:hover": {
                    borderColor: Colors.blue[1200],
                    backgroundColor: Colors.blue[100],
                  },
                }}
              >
                -30s
              </Button>
              <Button
                variant="outlined"
                onClick={handlePlus30}
                sx={{
                  borderRadius: 2,
                  textTransform: "none",
                  borderColor: Colors.blue[1200],
                  color: Colors.blue[1200],
                  "&:hover": {
                    borderColor: Colors.blue[1200],
                    backgroundColor: Colors.blue[100],
                  },
                }}
              >
                +30s
              </Button>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant={timerActive ? "outlined" : "contained"}
                onClick={timerActive ? handlePause : handleStart}
                sx={{
                  borderRadius: 2,
                  textTransform: "none",
                  minWidth: 120,
                  ...(timerActive && {
                    borderColor: Colors.blue[1200],
                    color: Colors.blue[1200],
                    "&:hover": {
                      borderColor: Colors.blue[1200],
                      backgroundColor: Colors.blue[100],
                    },
                  }),
                }}
              >
                {timerActive ? "Pause" : "Start"}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Snackbar
        open={snackbarOpen}
        message="Time is up!"
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      />
    </Dialog>
  );
};

export default RestTimer;
