import { Routes } from "../../../../routers";

export const navData = [
  {
    id: 1,
    title: "Home",
    content: "home",
    icon: "home",
    link: Routes.dashboard.url,
  },
  {
    id: 2,
    title: "Progress",
    content: "progress",
    icon: "stadium",
    link: Routes.progress.url,
  },
  {
    id: 3,
    title: "Workouts",
    content: "workouts",
    icon: "fitness_center",
    link: Routes.workouts.url,
  },
  {
    id: 4,
    title: "Timelines",
    content: "timeline",
    icon: "question_answer",
    link: `${Routes.athleteTimeline.url}`,
  },
  {
    id: 5,
    title: "Profile",
    content: "profile",
    icon: "perm_identity",
    link: "profile",
  },
];
