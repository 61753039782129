import { FC, useEffect, useState } from "react";
import { Alert, Box, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import { LoadingButton } from "@mui/lab";
import { Routes } from "shared/routers";
import { useTypedDispatch } from "shared/stores";
import { sharedStyles } from "shared/themes/shared/styles";
import {
  CombinedData,
  WorkoutProgramStatus,
  useChangeWorkoutStatusMutation,
  athleteApi,
  WorkoutSession,
  useSkipWorkoutMutation,
} from "../../shared/api";
import { WorkoutListViewItem } from "../Workouts/WorkoutDetails/ui/WorkoutListViewItem";
import { FinishWorkoutModal } from "../Workouts/WorkoutDetails/ui/FinishWorkoutModal";
import { SkipWorkoutModal } from "../Workouts/WorkoutDetails/ui/SkipWorkoutModal";
import { Feedback } from "../Workouts/WorkoutDetails/ui/Feedback";

interface LiveTrainingSelectedWorkoutProps {
  selectedUserId: number | null;
  selectedWorkoutId: number | null;
  handleProgressWorkout: () => void;
}

const { IN_PROGRESS, NOT_STARTED, COMPLETED, SKIPPED } = WorkoutProgramStatus;

const LiveTrainingSelectedWorkout: FC<LiveTrainingSelectedWorkoutProps> = ({
  selectedUserId,
  selectedWorkoutId,
  handleProgressWorkout,
}) => {
  const { t } = useTranslation();
  const getWorkouts = athleteApi.endpoints.getWorkout.initiate(
    selectedWorkoutId!!
  );
  const getCombinedWorkoutItems =
    athleteApi.endpoints.getCombinedWorkoutItems.initiate({
      userId: selectedUserId!!,
      workoutId: selectedWorkoutId!!,
    });
  const [skipWorkout, { isLoading: isLoadingSkip }] = useSkipWorkoutMutation();

  const [isLoadingWorkout, setIsLoadingWorkout] = useState(true);
  const [workout, setWorkout] = useState<WorkoutSession>();
  const [isLoading, setIsLoading] = useState(true);
  const [combinedData, setCombinedData] = useState<CombinedData[]>();

  const [fetchErrorMessage, setFetchErrorMessage] = useState<string>("");
  const dispatch = useTypedDispatch();

  async function callGetWorkouts() {
    try {
      setIsLoadingWorkout(true);
      const response = await dispatch(getWorkouts);
      setWorkout(response.data ?? undefined);
    } catch (error: any) {
      setFetchErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setIsLoadingWorkout(false);
    }
  }

  async function callGetCombinedWorkoutItems() {
    try {
      setIsLoading(true);
      const response = await dispatch(getCombinedWorkoutItems);
      setCombinedData(response.data ?? []);
    } catch (error: any) {
      setFetchErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (selectedUserId !== null) {
      setIsLoading(true);
      setIsLoadingWorkout(true);
      setWorkout(undefined);
      setFetchErrorMessage("");
    }
    if (selectedUserId) callGetWorkouts();
    if (selectedUserId && selectedWorkoutId) callGetCombinedWorkoutItems();
  }, [selectedUserId, selectedWorkoutId]);

  const [showFeedback, setShowFeedback] = useState(false);
  const [isShowFinishWorkoutModal, setIsShowFinishWorkoutModal] =
    useState(false);
  const [isShowSkipWorkoutModal, setIsShowSkipWorkoutModal] = useState(false);

  const [changeWorkoutStatus, { isLoading: isLoadingChangeStatus }] =
    useChangeWorkoutStatusMutation();

  const buttonProps: { [key: string]: { title: any; background: string } } = {
    [IN_PROGRESS]: {
      title: "Complete session",
      background: `${Colors.blue[1200]}`,
    },
    [NOT_STARTED]: {
      title: "Complete session",
      background: `${Colors.blue[1200]}`,
    },
    [SKIPPED]: {
      title: t("session-card.skip-workout"),
      background: `${Colors.gray[100]}`,
    },
  };

  const btnTitle = buttonProps[workout?.workoutStatus ?? ""];

  const isInProgress = workout?.workoutStatus === IN_PROGRESS;
  const isCompleted = workout?.workoutStatus === COMPLETED;

  const onProgressWorkout = async () => {
    try {
      await changeWorkoutStatus({
        ...workout,
        description: "",
        workoutStatus: COMPLETED,
      }).unwrap();

      handleProgressWorkout();
    } catch (error) {
      console.error("Error progressing workout");
    }
  };

  const handleFinishWorkoutModal = () => {
    setIsShowFinishWorkoutModal(!isShowFinishWorkoutModal);
  };

  const handleSkipWorkoutModal = () => {
    setIsShowSkipWorkoutModal(!isShowSkipWorkoutModal);
  };

  const handleCheckStatusBeforeRequest = () => {
    handleFinishWorkoutModal();
  };

  const handleSkippedWorkoutRequest = async () => {
    await changeWorkoutStatus({
      ...workout,
      description: "",
      workoutStatus: SKIPPED,
    }).unwrap();

    await skipWorkout({
      workoutId: workout!.id,
      workoutProgramId: workout!.workoutProgramId,
    }).unwrap();

    window.location.reload();
  };

  const groupedExercises = combinedData?.reduce<Record<string, CombinedData[]>>(
    (groups, exercise) => {
      const key = exercise.supersetGroup || exercise.exerciseId.toString(); // Using exercise.id as a fallback key
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(exercise);
      return groups;
    },
    {}
  ) ?? {
    "": [],
  };

  const groupedExercisesArray = Object.values(groupedExercises).sort((a, b) => {
    const orderA = a[0]?.order || 0;
    const orderB = b[0]?.order || 0;
    return orderA - orderB;
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        flex: 3,
        gap: "12px",
        height: "100%",
        overflow: "auto",
      }}
    >
      {fetchErrorMessage && (
        <Alert
          variant="outlined"
          severity="error"
          sx={{ mb: "10px", alignItems: "center" }}
        >
          <Typography sx={sharedStyles.body.timeline.cardBody}>
            {fetchErrorMessage}
          </Typography>
        </Alert>
      )}
      {workout && (
        <Feedback
          showFeedback={showFeedback}
          finishedWorkout={workout}
          workoutId={selectedWorkoutId ?? 0}
          linkTo={Routes.liveTraining.url}
        />
      )}
      {isLoading ||
      isLoadingWorkout ||
      !selectedUserId ||
      !selectedWorkoutId ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ mt: "10px" }}>
          {!showFeedback && (
            <>
              {groupedExercisesArray.map((exercises, index) => {
                if (exercises.length > 1) {
                  return (
                    <Box
                      key={index}
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        mb: "20px",
                        boxShadow:
                          "0px 10px 40px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
                        borderRadius: "12px",
                        p: "20px",
                        backgroundColor: "#FAFAFA",
                        border: "1px solid #F5F5F5",
                        boxSizing: `border-box`,
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "18px",
                          lineHeight: "140%",
                          color: `${Colors.blue[1300]}`,
                          mb: 2,
                        }}
                      >
                        Superset
                      </Typography>
                      {exercises.map((exercise: CombinedData) => (
                        <WorkoutListViewItem
                          key={exercise.exerciseId}
                          exercise={exercise}
                          selectedUserId={selectedUserId!!}
                        />
                      ))}
                    </Box>
                  );
                }
                return exercises.map((exercise: CombinedData) => (
                  <WorkoutListViewItem
                    selectedUserId={selectedUserId!!}
                    key={exercise.exerciseId}
                    exercise={exercise}
                  />
                ));
              })}
            </>
          )}
          <FinishWorkoutModal
            isShowFinishWorkoutModal={isShowFinishWorkoutModal}
            onProgressWorkout={onProgressWorkout}
            setShowFeedback={setShowFeedback}
            setIsShowFinishWorkoutModal={setIsShowFinishWorkoutModal}
          />
          <SkipWorkoutModal
            isShowSkipWorkoutModal={isShowSkipWorkoutModal}
            onSkip={handleSkippedWorkoutRequest}
            setIsShowSkipWorkoutModal={setIsShowSkipWorkoutModal}
          />
          {workout &&
            workout.workoutStatus !== SKIPPED &&
            combinedData?.length !== 0 && (
              // eslint-disable-next-line
              <>
                {!isCompleted && workout && !showFeedback && (
                  <>
                    <LoadingButton
                      type="button"
                      onClick={handleCheckStatusBeforeRequest}
                      loading={isLoadingWorkout || isLoadingChangeStatus}
                      disabled={isLoadingWorkout || isLoadingChangeStatus}
                      sx={{
                        width: "100%",
                        height: "42px",
                      }}
                      variant="contained"
                      color={isInProgress ? "success" : "primary"}
                      size="large"
                    >
                      <Typography
                        variant="h2"
                        sx={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "26px",
                          letterSpacing: " 0.46px",
                          textTransform: "none",
                          color: `${Colors.gray[100]}`,
                        }}
                      >
                        {btnTitle?.title}
                      </Typography>
                    </LoadingButton>
                    <LoadingButton
                      type="button"
                      onClick={handleSkipWorkoutModal}
                      loading={
                        isLoadingWorkout ||
                        isLoadingChangeStatus ||
                        isLoadingSkip
                      }
                      disabled={
                        isLoadingWorkout ||
                        isLoadingChangeStatus ||
                        isLoadingSkip
                      }
                      sx={{
                        width: "100%",
                        height: "42px",
                        mt: 2,
                        textTransform: "none",
                        fontSize: "16px",
                      }}
                      variant="outlined"
                      color="primary"
                      size="large"
                    >
                      Skip Session
                    </LoadingButton>
                  </>
                )}
              </>
            )}
        </Box>
      )}
    </Box>
  );
};

export { LiveTrainingSelectedWorkout };
