import React from "react";
import { Box } from "@mui/system";
import { Card, Skeleton } from "@mui/material";
import { Colors } from "../../../../shared/themes";

const WorkoutPageSkeleton = () => {
  return (
    <Box sx={{ overflowY: "auto" }}>
      <Card
        sx={{
          width: "100%",
          position: "relative",
          borderRadius: 0,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
          pt: 8,
          pb: 3,
          pl: 3,
          pr: 3,
        }}
      >
        <Box>
          <Skeleton
            variant="text"
            width="60%"
            height={34}
            sx={{ mb: 1, bgcolor: "rgba(255,255,255,0.2)" }}
          />
          <Skeleton
            variant="text"
            width="40%"
            height={24}
            sx={{ mb: 1, bgcolor: "rgba(255,255,255,0.2)" }}
          />
          <Skeleton
            variant="text"
            width="30%"
            height={20}
            sx={{ bgcolor: "rgba(255,255,255,0.2)" }}
          />
        </Box>
      </Card>
      <Box
        sx={{
          padding: "9px 24px 23px 24px",
        }}
      >
        <Skeleton
          sx={{ marginTop: "10px", height: "34px" }}
          variant="rectangular"
          animation="wave"
        />
        <Skeleton
          sx={{ marginTop: "10px", height: "25px", mb: "17px" }}
          variant="rectangular"
          animation="wave"
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height="184px"
          sx={{
            bgcolor: `${Colors.gray[500]}`,
            marginBottom: "55px",
            marginTop: "16px",
          }}
        />
        <Skeleton
          sx={{ marginTop: "10px", height: "25px", mb: "17px" }}
          variant="rectangular"
          animation="wave"
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height="184px"
          sx={{
            bgcolor: `${Colors.gray[500]}`,
            marginBottom: "16px",
            marginTop: "16px",
          }}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height="184px"
          sx={{
            bgcolor: `${Colors.gray[500]}`,
            marginBottom: "55px",
            marginTop: "16px",
          }}
        />
      </Box>
    </Box>
  );
};

export { WorkoutPageSkeleton };
