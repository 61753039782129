import React from "react";
import {
  Box,
  Typography,
  Grid,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { ActivityCard } from "./ActivityCard";
import { determinePerformanceLevel, getGradientForScore } from "./formatters";
import { TrendDataItem } from "../AdminDashboard/utils/types";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface StepsDetailCardProps {
  insights: {
    trendData: TrendDataItem[];
    bestDay: TrendDataItem;
    worstDay: TrendDataItem;
    goalAchievementDays: number;
    totalDays: number;
    averageDailySteps: number;
  };
  open: boolean;
  onClose: () => void;
  todayStats: {
    steps: number;
    activeCalories: number;
  };
}

export const StepsDetailCard: React.FC<StepsDetailCardProps> = ({
  insights,
  open,
  onClose,
  todayStats,
}) => {
  const todayStepsPerformance = determinePerformanceLevel(
    todayStats.steps,
    10000
  );
  const bestDayPerformance = determinePerformanceLevel(
    insights.bestDay?.steps ?? 0,
    insights.bestDay?.goal ?? 0
  );
  const worstDayPerformance = determinePerformanceLevel(
    insights.worstDay?.steps ?? 0,
    insights.worstDay?.goal ?? 0
  );
  const averageDailyStepsPerformance = determinePerformanceLevel(
    insights.averageDailySteps ?? 0,
    insights.trendData?.[0]?.goal ?? 0
  );
  const stepGoalAchievementPerformance = determinePerformanceLevel(
    insights.goalAchievementDays ?? 0,
    insights.trendData?.length ?? 0
  );
  const caloriesBurnedPerformance = determinePerformanceLevel(
    todayStats.activeCalories,
    500
  );

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          pt: 6,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
            Activity Details
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle2" sx={{ color: "text.secondary", mt: 1 }}>
          Here&apos;s your activity summary over the last{" "}
          {insights.trendData.length} days,
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={6}>
            <ActivityCard
              title="Today"
              value={todayStats.steps}
              target={10000}
              unit="steps"
              gradient={getGradientForScore(todayStepsPerformance)}
            />
          </Grid>
          <Grid item xs={6}>
            <ActivityCard
              title="Calories burned today"
              value={todayStats.activeCalories}
              target={500}
              unit="kcal"
              gradient={getGradientForScore(caloriesBurnedPerformance)}
            />
          </Grid>
          <Grid item xs={6}>
            <ActivityCard
              title="Best day"
              value={insights.bestDay?.steps}
              target={insights.bestDay?.goal}
              unit="steps"
              gradient={getGradientForScore(bestDayPerformance)}
            />
          </Grid>

          <Grid item xs={6}>
            <ActivityCard
              title="Worst day"
              value={insights.worstDay?.steps}
              target={insights.worstDay?.goal}
              unit="steps"
              gradient={getGradientForScore(worstDayPerformance)}
            />
          </Grid>

          <Grid item xs={6}>
            <ActivityCard
              title="Daily Average"
              value={Math.round(insights.averageDailySteps ?? 0)}
              target={insights.trendData?.[0]?.goal ?? 0}
              unit="steps"
              gradient={getGradientForScore(averageDailyStepsPerformance)}
            />
          </Grid>

          <Grid item xs={6}>
            <ActivityCard
              title="Achieved Step Goal"
              value={insights?.goalAchievementDays ?? 0}
              target={insights?.trendData?.length ?? 0}
              unit="days"
              gradient={getGradientForScore(stepGoalAchievementPerformance)}
            />
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
