export const Routes = {
  signIn: {
    url: "/",
    title: "FitFocus",
  },
  dashboard: {
    url: "/dashboard",
    title: "FitFocus - Dashboard",
  },
  progress: {
    url: "/progress",
    title: "FitFocus - Progress",
  },
  workouts: {
    url: "/workouts",
    title: "FitFocus - Workouts",
  },
  programs: {
    url: "/programs",
    title: "FitFocus - Programs",
  },
  profile: {
    url: "/profile",
    title: "FitFocus - Profile",
  },
  clients: {
    url: "/clients",
    title: "FitFocus - Clients",
  },
  bookings: {
    url: "/bookings",
    title: "FitFocus - Bookings",
  },
  trainingInsights: {
    url: "/training-insights",
    title: "FitFocus - Training Insights",
  },
  athleteTrainingInsights: {
    url: "/my-training-insights",
    title: "FitFocus - My Training Insights",
  },
  liveTraining: {
    url: "/live-training",
    title: "FitFocus - Live Training",
  },
  billing: {
    url: "/billing",
    title: "FitFocus - Billing",
  },
  exerciseLibrary: {
    url: "/exercise-library",
    title: "FitFocus - Exercise Library",
  },
  exercise: {
    url: "/exercise",
    title: "FitFocus - Exercise",
  },
  timeline: {
    url: "/timeline",
    title: "FitFocus - Timeline",
  },
  workflows: {
    url: "/workflows",
    title: "FitFocus - Workflows",
  },
  individualWorkflow: {
    url: "/workflows/:workflowId",
    title: "FitFocus - Workflow",
  },
  athleteTimeline: {
    url: "/my-timeline",
    title: "FitFocus - My Timeline",
  },
  athleteBookings: {
    url: "/my-bookings",
    title: "FitFocus - My Bookings",
  },
  organisation: {
    url: "/organisation",
    title: "FitFocus - Organisation",
  },
  organisationIntegrationSetupSuccess: {
    url: "/organisation/connector-setup/success",
    title: "FitFocus - Organisation",
  },
  modernWorkoutProgramBuilder: {
    url: "/modern-workout-program-designer",
    title: "FitFocus - Program Builder",
  },
  workoutProgramBuilderSuccess: {
    url: "/workout-program-designer/success",
    title: "FitFocus - Program Builder",
  },
  fitnessProfessionalSignup: {
    url: "/register/fitness-professional",
    title: "FitFocus",
  },
  fitnessProfessionalSignupEmailVerification: {
    url: "/register/fitness-professional/email-verification",
    title: "FitFocus",
  },
  fitnessProfessionalSignupSuccess: {
    url: "/register/fitness-professional/success",
    title: "FitFocus",
  },
  termsOfService: {
    url: "/terms-of-service",
    title: "FitFocus",
  },
  clientSignupEmailVerification: {
    url: "/register/client/email-verification",
    title: "FitFocus",
  },
  clientSignupSuccess: {
    url: "/register/client/success",
    title: "FitFocus",
  },
  forgotPassword: {
    url: "/reset-password",
    title: "FitFocus",
  },
  forgotPasswordVerification: {
    url: "/reset-password/verification",
    title: "FitFocus",
  },
  forgotPasswordSuccess: {
    url: "/reset-password/success",
    title: "FitFocus",
  },
  leads: {
    url: "/leads",
    title: "FitFocus - Leads",
  },
  userNotes: {
    url: "/user-notes",
    title: "FitFocus - Treatment Notes",
  },
  nutrition: {
    url: "/nutrition",
    title: "FitFocus - Nutrition",
  },
  athleteNutrition: {
    url: "/my-nutrition",
    title: "FitFocus - My Nutrition",
  },
  leaderboard: {
    url: "/leaderboard",
    title: "FitFocus - Leaderboard",
  },
  athleteLeaderboard: {
    url: "/athlete-leaderboard",
    name: "Athlete Leaderboard",
  },
};
