import React, { FC, useState } from "react";
import { Box } from "@mui/system";
import {
  Button,
  Icon,
  Rating,
  TextField,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Colors } from "shared/themes";
import { WorkoutSession, useSendFeedbackMutation } from "shared/api";
import { Routes } from "shared/routers";
import { SubmitFeedback } from "./SubmitFeedback";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FeedbackProps {
  showFeedback: boolean;
  finishedWorkout: WorkoutSession;
  workoutId: number;
  linkTo?: string;
}

interface SubmitValues {
  description: string;
  rating: number;
  workoutProgramId: number;
  workoutId: number;
}

const FeedbackSchema = Yup.object().shape({
  rating: Yup.number()
    .moreThan(0, "feedback.rating-required")
    .required("feedback.rating-required"),
});

const Feedback: FC<FeedbackProps> = ({
  showFeedback,
  finishedWorkout,
  workoutId,
  linkTo = Routes.dashboard.url,
}) => {
  const { t } = useTranslation();
  const [sendFeedback, { isLoading }] = useSendFeedbackMutation();
  const [feedbackFinalStep, setFeedbackFinalStep] = useState(false);

  const onSubmit = async (params: SubmitValues) => {
    try {
      await sendFeedback(params).unwrap();
      setFeedbackFinalStep(true);
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
    }
  };

  const { values, handleSubmit, handleChange, handleBlur, errors, resetForm } =
    useFormik({
      initialValues: {
        description: "",
        rating: 0,
        workoutProgramId: finishedWorkout.workoutProgramId,
        workoutId,
      },
      validationSchema: FeedbackSchema,
      onSubmit: (params) => {
        onSubmit(params);
      },
    });

  return (
    <Dialog fullScreen open={showFeedback} TransitionComponent={Transition}>
      <AppBar
        sx={{
          position: "relative",
          pt: 7,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
        }}
      >
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
            Leave feedback
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              mb: "16px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "140%",
                color: Colors.gray[1000],
                mb: "8px",
              }}
            >
              {t("feedback.how-was-your-workout")}
            </Typography>
            <Rating
              value={Number(values.rating)}
              onBlur={handleBlur("rating")}
              onChange={handleChange("rating")}
              sx={{
                fontSize: "1.87rem",
              }}
            />
            {errors.rating && (
              <Box sx={{ color: Colors.red[200], fontSize: "14px" }}>
                {t(errors.rating)}
              </Box>
            )}
          </Box>

          <Box
            sx={{
              width: "100%",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "140%",
              color: Colors.gray[1000],
              mb: 2,
            }}
          >
            {t("feedback.further-comments")}
          </Box>

          <TextField
            variant="outlined"
            name="description"
            fullWidth
            multiline
            rows={4}
            type="text"
            value={values.description}
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <Button
            onClick={() => handleSubmit()}
            disabled={isLoading}
            variant="contained"
            color="primary"
            sx={{
              marginTop: "24px",
              width: "100%",
              height: "42px",
              textTransform: "none",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "26px",
              letterSpacing: "0.46px",
            }}
          >
            {t("feedback.send-feedback")}
            <Icon sx={{ ml: 1 }}>send_icon</Icon>
          </Button>
        </Box>
      </Box>

      {feedbackFinalStep && <SubmitFeedback linkTo={linkTo} />}
    </Dialog>
  );
};

export { Feedback };
