import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import SahhaService from "SahhaService";
import { Device } from "@capacitor/device";
import { Router } from "./shared/routers";
import { store, persistor } from "./shared/stores";
import { Themes } from "./shared/themes/Themes";
import OneSignalService from "./OneSignalService";
import "./index.css";

const App = () => {
  const configureSahha = async () => {
    const device = await Device.getInfo();
    if (device.platform === "web") {
      return;
    }
    const sahhaService = SahhaService.getInstance();
    const configError = await sahhaService.configureSahha();
    if (configError instanceof Error) {
      console.log("Health data failed to configure");
    }
  };

  useEffect(() => {
    const initOneSignal = async () => {
      const oneSignalService = OneSignalService.getInstance();
      await oneSignalService.oneSignalInit();
    };

    initOneSignal();
    configureSahha();
  }, []);

  return (
    <ThemeProvider theme={Themes}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HelmetProvider>
            <Helmet htmlAttributes={{ lang: "en" }}>
              <title>FitFocus</title>
              <meta charSet="utf-8" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
              />
              <meta name="theme-color" content="#fff" />
              <meta name="fitfocus" content="promotional materials" />
              <link
                href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp"
                rel="stylesheet"
              />
            </Helmet>
            <Router />
          </HelmetProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
