import { FC, useState, useEffect } from "react";
import { Box } from "@mui/system";
import {
  Alert,
  Modal,
  Typography,
  Icon,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { sharedStyles } from "shared/themes/shared/styles";
import {
  WorkoutProgramItem,
  useDeleteWorkoutProgramMutation,
} from "shared/api";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { Colors } from "shared/themes";

interface DeleteWorkoutProgramModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  workoutProgram?: WorkoutProgramItem;
}

const DeleteWorkoutProgramModal: FC<DeleteWorkoutProgramModalProps> = ({
  isOpenModal,
  handleCloseModal,
  workoutProgram,
}) => {
  const [deleteWorkoutProgram, { isLoading }] =
    useDeleteWorkoutProgramMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    if (isOpenModal) {
      setConfirmDelete(false);
    }
  }, [isOpenModal]);

  const handleDelete = async () => {
    if (!workoutProgram?.id) return;

    try {
      await deleteWorkoutProgram(workoutProgram.id).unwrap();
      setSuccessMessage("Program successfully deleted");
    } catch (error: any) {
      setErrorMessage(
        error?.data?.message || "An error occurred. Please try again later"
      );
    } finally {
      setTimeout(() => {
        setErrorMessage("");
        setSuccessMessage("");
        handleCloseModal();
      }, 1500);
    }
  };

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box sx={sharedStyles.containers.modalContent}>
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: Colors.red[200],
              }}
            >
              Delete Program: {workoutProgram?.nameOfProgram}
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Typography
            sx={{
              width: "100%",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              mt: 2,
              mb: 1,
            }}
          >
            Warning: This action cannot be undone. The program, all versions and
            all associated data will be permanently deleted.
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={confirmDelete}
                onChange={(e) => setConfirmDelete(e.target.checked)}
                sx={{
                  color: Colors.red[200],
                  "&.Mui-checked": {
                    color: Colors.red[200],
                  },
                }}
              />
            }
            label="I understand that this action is irreversible and I want to delete this program"
            sx={{
              mb: 3,
              "& .MuiFormControlLabel-label": {
                fontSize: "14px",
                color: Colors.red[200],
              },
            }}
          />
          <Box sx={{ display: "flex", gap: 2 }}>
            <PrimaryButton
              value="Delete Program"
              variant="contained"
              color="error"
              fullWidth
              loading={isLoading}
              disabled={!confirmDelete || isLoading}
              onClick={() => handleDelete()}
            />
          </Box>
          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { DeleteWorkoutProgramModal };
