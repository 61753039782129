/* eslint-disable react/jsx-no-useless-fragment */
import { FC, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import { Colors } from "shared/themes";
import {
  useGetCombinedWorkoutItemsQuery,
  CombinedData,
  WorkoutSession,
  WorkoutProgramStatus,
} from "shared/api";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import { WorkoutListViewItem } from "./WorkoutListViewItem";
import { WorkoutPageSkeleton } from "./WorkoutPageSkeleton";

interface WorkoutListProps {
  workout: WorkoutSession;
  isCompletedWorkoutProgram: boolean;
  isInProgress: boolean;
  isLoadingSkip: boolean;
  workoutSkipped: boolean;
  handleCheckStatusBeforeRequest: () => void;
  handleSkipWorkoutModal: () => void;
  setTotalExercises: (totalExercises: number) => void;
}

const { COMPLETED } = WorkoutProgramStatus;

const WorkoutList: FC<WorkoutListProps> = ({
  workout,
  isCompletedWorkoutProgram,
  isInProgress,
  isLoadingSkip,
  workoutSkipped,
  handleCheckStatusBeforeRequest,
  handleSkipWorkoutModal,
  setTotalExercises,
}) => {
  const userId = useTypedSelector(userSelectors.userId);

  const isNotCompleted = workout?.workoutStatus !== COMPLETED;

  const { data: combinedData, isLoading } = useGetCombinedWorkoutItemsQuery({
    userId,
    workoutId: workout.id,
  });

  const groupedExercises = combinedData?.reduce<Record<string, CombinedData[]>>(
    (groups, exercise) => {
      const key = exercise.supersetGroup || exercise.exerciseId.toString(); // Using exercise.id as a fallback key
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(exercise);
      return groups;
    },
    {}
  ) ?? {
    "": [],
  };

  const groupedExercisesArray = Object.values(groupedExercises).sort((a, b) => {
    const orderA = a[0]?.order || 0;
    const orderB = b[0]?.order || 0;
    return orderA - orderB;
  });

  useEffect(() => {
    if (combinedData) {
      setTotalExercises(combinedData.length);
    }
  }, [combinedData, setTotalExercises]);

  return (
    <Box
      sx={{
        width: "100%",
        padding: `24px 24px 36px`,
        height: "100vh",
        overflowY: "auto",
      }}
    >
      {isLoading ? (
        <WorkoutPageSkeleton />
      ) : (
        <>
          {groupedExercisesArray.map((exercises, index) => {
            if (exercises.length > 1) {
              return (
                <Box
                  key={index}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    mb: "20px",
                    boxShadow:
                      "0px 10px 40px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
                    borderRadius: "12px",
                    p: "20px",
                    backgroundColor: "#FAFAFA",
                    border: "1px solid #F5F5F5",
                    boxSizing: `border-box`,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "140%",
                      color: `${Colors.blue[1300]}`,
                      mb: 2,
                    }}
                  >
                    Superset
                  </Typography>
                  {exercises.map((exercise: CombinedData) => (
                    <WorkoutListViewItem
                      key={exercise.exerciseId}
                      exercise={exercise}
                      selectedUserId={userId!!}
                    />
                  ))}
                </Box>
              );
            }
            return exercises.map((exercise: CombinedData) => (
              <WorkoutListViewItem
                key={exercise.exerciseId}
                exercise={exercise}
                selectedUserId={userId!!}
              />
            ));
          })}
        </>
      )}
      {!isCompletedWorkoutProgram &&
        workout &&
        isNotCompleted &&
        !workoutSkipped && (
          <>
            <LoadingButton
              onClick={handleCheckStatusBeforeRequest}
              loading={isLoading || isLoadingSkip}
              disabled={isLoading || isLoadingSkip}
              sx={{
                width: "100%",
                height: "42px",
              }}
              variant="contained"
              color={isInProgress ? "success" : "primary"}
              size="large"
            >
              {!isLoading && !isLoadingSkip ? (
                <Typography
                  variant="h2"
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "26px",
                    letterSpacing: " 0.46px",
                    textTransform: "none",
                    color: `${Colors.gray[100]}`,
                  }}
                >
                  Complete session
                </Typography>
              ) : (
                ""
              )}
            </LoadingButton>
            <LoadingButton
              type="button"
              onClick={handleSkipWorkoutModal}
              loading={isLoading || isLoadingSkip}
              disabled={isLoading || isLoadingSkip}
              sx={{
                width: "100%",
                height: "42px",
                mt: 2,
                mb: 2,
                textTransform: "none",
                fontSize: "16px",
              }}
              variant="outlined"
              color="primary"
              size="large"
            >
              Skip session
            </LoadingButton>
          </>
        )}
    </Box>
  );
};

export { WorkoutList };
