import React, { FC, useEffect, useState } from "react";
import {
  TextField,
  Typography,
  useMediaQuery,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Colors } from "shared/themes";
import {
  useGetWorkoutProgramsQuery,
  useGetWorkoutProgramsCountQuery,
  WorkoutProgramItem,
  useGetAllWorkoutProgramsQuery,
} from "shared/api";
import { createAllUsersRows } from "shared/helpers";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar/IdenticonAvatar";
import { ChangeStatusModal } from "components/Modals/ChangeStatusModal";
import { CopyAndAssignProgramToUserModal } from "components/Modals/CopyAndAssignProgramToUserModal";
import SearchIcon from "@mui/icons-material/Search";
import { DeleteWorkoutProgramModal } from "components/Modals/DeleteWorkoutProgramModal";
import { StatusItem } from "./StatusItem";
import { ProgramItemActions } from "./ProgramItemActions";
import { ProgramsSkeleton } from "./ProgramsSkeleton";
import { UserCategoryRadioGroup } from "../../../Users/UserCategoryRadioGroup";

interface ProgramsContainerProps {
  getUserIdForWorkoutCreation: (userId: number) => void;
}

export type ProgramRow = {
  id: number;
  nameOfProgram: string;
  date: string;
  workoutProgramStatus: string;
  userId: number;
  userFullName: string;
  profileImageUrl: string;
};

const ProgramsContainer: FC<ProgramsContainerProps> = ({
  getUserIdForWorkoutCreation,
}) => {
  const { t } = useTranslation();
  const [userCategoryRadio, setUserCategoryRadio] = useState<string>("");
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [selectedWorkoutProgram, setSelectedWorkoutProgram] =
    useState<WorkoutProgramItem>();
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
  const [copyProgramModal, setOpenCopyProgramModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { data: workoutProgramsLength } = useGetWorkoutProgramsCountQuery({
    countOnly: true,
    userCategory: userCategoryRadio,
  });

  const {
    data: workoutPrograms,
    isLoading: isLoadingWorkouts,
    refetch: refetchWorkoutPrograms,
  } = useGetWorkoutProgramsQuery({
    count: pageSize,
    page,
    userCategory: userCategoryRadio,
  });
  const { data: allWorkoutPrograms, isLoading: isLoadingAllItems } =
    useGetAllWorkoutProgramsQuery({
      orderByDescending: true,
      userCategory: userCategoryRadio,
    });
  const [searchValue, setSearchValue] = useState<string>("");
  const [rows, setRows] = useState<ProgramRow[]>([]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const [rowCountState, setRowCountState] = useState(0);

  useEffect(() => {
    refetchWorkoutPrograms();
  }, []);

  useEffect(() => {
    if (!workoutPrograms) return;
    if (searchValue) {
      const filteredData = allWorkoutPrograms?.filter((item) => {
        return (
          item?.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item?.userFullName?.toLowerCase().includes(searchValue.toLowerCase())
        );
      });
      const rowsData = createAllUsersRows(filteredData!);
      setRows(rowsData!);
      setPage(1);
      setPageSize(100);
      setRowCountState(rowsData?.length!);
    } else {
      const rowsData = createAllUsersRows(workoutPrograms!);
      setRows(rowsData);
      setRowCountState((prevRowCountState) =>
        workoutProgramsLength !== undefined
          ? workoutProgramsLength
          : prevRowCountState
      );
    }
  }, [searchValue, workoutPrograms]);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      workoutProgramsLength !== undefined
        ? workoutProgramsLength
        : prevRowCountState
    );
  }, [workoutProgramsLength, setRowCountState]);

  const isMobile = useMediaQuery("(max-width:600px)");

  const columns: GridColDef[] = [
    {
      field: "photoUrl",
      headerName: "",
      width: 60,
      sortable: false,
      renderCell: (params) => {
        const { userId, profileImageUrl } = params.row;
        return (
          <IdenticonAvatar
            seedValue={userId}
            profileImageUrl={profileImageUrl}
          />
        );
      },
      filterable: false,
    },
    {
      field: "userFullName",
      headerName: t("programs.table-name"),
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const { userFullName } = params.row;
        return (
          <Box
            sx={{
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: isMobile ? "0.875rem" : "14px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {userFullName}
          </Box>
        );
      },
    },
    {
      field: "nameOfProgram",
      headerName: t("programs.table-program-name"),
      minWidth: 150,
      flex: 1.5,
      sortable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: isMobile ? "0.875rem" : "14px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "duration",
      headerName: t("programs.table-duration"),
      flex: 1,
      hide: isMobile,
      sortable: false,
    },
    {
      field: "date",
      headerName: t("programs.date"),
      flex: 1.5,
      hide: isMobile,
      sortable: false,
    },
    {
      field: "workoutProgramStatus",
      headerName: "Status",
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return <StatusItem status={params.value} />;
      },
    },
    {
      field: "menu",
      headerName: "",
      minWidth: 70,
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        const { userId } = params.row;
        return (
          <ProgramItemActions
            userId={userId}
            workoutProgramId={params.row.id}
            workoutProgramStatus={params.row.workoutProgramStatus}
            getUserIdForWorkoutCreation={getUserIdForWorkoutCreation}
            onChangeStatusClick={() => {
              setSelectedWorkoutProgram(params.row);
              setOpenChangeStatusModal(true);
            }}
            onCopyModalClick={() => {
              setSelectedWorkoutProgram(params.row);
              setOpenCopyProgramModal(true);
            }}
            onDeleteClick={() => {
              setSelectedWorkoutProgram(params.row);
              setDeleteModalOpen(true);
            }}
          />
        );
      },
      filterable: false,
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "800px",
        bgcolor: `${Colors.gray[100]}`,
        borderRadius: "8px",
        padding: { xs: "8px", sm: "32px" },
        mb: "16px",
      }}
    >
      {!isLoadingWorkouts ? (
        <Box sx={{ height: "700px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
              mb: 2,
              px: { xs: 1, sm: 0 },
              justifyContent: { sm: "flex-end" },
              alignItems: { sm: "center" },
            }}
          >
            <UserCategoryRadioGroup
              value={userCategoryRadio}
              setValue={(e: string) => setUserCategoryRadio(e)}
            />
            <TextField
              label="Search by user..."
              variant="standard"
              value={searchValue}
              onChange={handleOnChange}
              disabled={isLoadingAllItems}
              sx={{
                minWidth: { xs: "100%", sm: "200px" },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" size="small" aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <DataGrid
            headerHeight={56}
            rowHeight={isMobile ? 52 : 56}
            pageSize={pageSize}
            onPageChange={(newPage) => setPage(newPage + 1)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pagination
            rows={rows ?? []}
            columns={columns}
            rowCount={rowCountState}
            paginationMode="server"
            onRowClick={(params) => {
              setSelectedWorkoutProgram(params.row);
            }}
            sx={{
              "& .MuiDataGrid-columnSeparator": {
                visibility: "hidden",
              },
              "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                bgcolor: `${Colors.gray[1700]}`,
              },
              "& .MuiDataGrid-menuIconButton": {
                display: "none",
              },
              "& .MuiDataGrid-cell": {
                padding: isMobile ? "0 8px" : "0 16px",
              },
              "& .MuiDataGrid-virtualScroller": {
                overflowX: "auto !important",
                "&::-webkit-scrollbar": {
                  height: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: Colors.gray[500],
                  borderRadius: "4px",
                },
              },
            }}
          />
          <ChangeStatusModal
            isOpenModal={openChangeStatusModal}
            handleCloseModal={() =>
              setOpenChangeStatusModal(!openChangeStatusModal)
            }
            workoutProgram={selectedWorkoutProgram}
          />
          <CopyAndAssignProgramToUserModal
            isOpenModal={copyProgramModal}
            handleCloseModal={() => setOpenCopyProgramModal(!copyProgramModal)}
            workoutProgram={selectedWorkoutProgram}
          />
          <DeleteWorkoutProgramModal
            isOpenModal={deleteModalOpen}
            handleCloseModal={() => setDeleteModalOpen(false)}
            workoutProgram={selectedWorkoutProgram}
          />
        </Box>
      ) : (
        <ProgramsSkeleton />
      )}
    </Box>
  );
};
export { ProgramsContainer };
