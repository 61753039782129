/* eslint-disable no-nested-ternary */
import {
  Box,
  Typography,
  Grid,
  Button,
  Skeleton,
  Icon,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTypedDispatch, useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import { setCurrentWorkout } from "shared/stores/workout";
import {
  useGetWorkoutsQuery,
  useGetHealthScoresForUserQuery,
  UserRole,
} from "shared/api";
import { Colors, getGraphicSvg, Images } from "shared/themes";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Routes } from "shared/routers";
import { useNavigate } from "react-router-dom";
import { Device } from "@capacitor/device";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  getActivityInsights,
  getSleepMetrics,
  getReadinessMetrics,
} from "pages/Dashboard/AdminDashboard/utils/healthMetricsUtils";
import { SafeAreaBox } from "components/SafeAreaBox/SafeAreaBox";
import SahhaService from "SahhaService";
import { Sahha } from "sahha-capacitor";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import PersonIcon from "@mui/icons-material/Person";
import { OutlinedIcon } from "components/OutlinedIcon/OutlinedIcon";
import { AnyType } from "shared/helpers";
import { ActivityCard } from "./ActivityCard";
import { StepsDetailCard } from "./StepsDetailCard";
import { determinePerformanceLevel, getGradientForScore } from "./formatters";
import { SleepDetailsCard } from "./SleepDetailsCard";
import { ReadinessDetailsCard } from "./ReadinessDetailsCard";
import { SleepCard } from "./SleepCard";
import { ReadinessCard } from "./ReadinessCard";
import { HealthPermissionsDialog } from "./HealthPermissionsDialog";
import { AthleteLeaderboard } from "../../Leaderboard/AthleteLeadeboard";

dayjs.extend(utc);
dayjs.extend(timezone);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MOBILE_APP_MESSAGE =
  "Health data cannot be retrieved. Authorisation is required to access this feature.";
const WEB_APP_MESSAGE =
  "To access health data insights, this feature must be enabled through the mobile app.";

const AthleteDashboard: FC = () => {
  const dispatch = useTypedDispatch();
  const user = useTypedSelector(userSelectors.all);
  const { data, isLoading } = useGetWorkoutsQuery();
  const [isMobileApp, setIsMobileApp] = useState(false);

  const { data: activityHealthData, isLoading: activityHealthDataLoading } =
    useGetHealthScoresForUserQuery(
      {
        type: "activity",
        userId: user?.id!,
      },
      {
        skip: !user?.id,
      }
    );
  const { data: readinessHealthData, isLoading: readinessHealthDataLoading } =
    useGetHealthScoresForUserQuery(
      {
        type: "readiness",
        userId: user?.id!,
      },
      {
        skip: !user?.id,
      }
    );

  const { data: sleepHealthData, isLoading: sleepHealthDataLoading } =
    useGetHealthScoresForUserQuery(
      {
        type: "sleep",
        userId: user?.id!,
      },
      {
        skip: !user?.id,
      }
    );

  const checkDeviceIsMobileApp = async () => {
    const device = await Device.getInfo();
    if (device.platform === "android" || device.platform === "ios") {
      setIsMobileApp(true);
      return;
    }
    setIsMobileApp(false);
  };

  const activityInsights = getActivityInsights(
    activityHealthData?.healthScores
  );
  const sleepInsights = getSleepMetrics(sleepHealthData?.healthScores);
  const readinessInsights = getReadinessMetrics(
    readinessHealthData?.healthScores
  );

  const nextWorkout = data?.remainingWorkouts?.[0];

  const [showStepsDetail, setShowStepsDetail] = useState(false);
  const [showSleepDetail, setShowSleepDetail] = useState(false);
  const [showReadinessDetail, setShowReadinessDetail] = useState(false);
  const [sensorStatus, setSensorStatus] = useState<boolean>(false);
  const [authStatus, setAuthStatus] = useState<boolean>(false);
  const [isApiKeyAuthorised, setIsApiKeyAuthorised] = useState(false);
  const [showHealthPermissions, setShowHealthPermissions] = useState(false);

  const isDashboardNotActive =
    showStepsDetail || showSleepDetail || showReadinessDetail;

  const profileUrl = `${Routes.profile.url}/${user.id}`;
  const navigate = useNavigate();

  const handleNavigateToProfileUrl = () => {
    navigate(profileUrl);
  };

  const [dailyStats, setDailyStats] = useState<{
    steps: number;
    activeCalories: number;
  }>({
    steps: 0,
    activeCalories: 0,
  });

  const todayStepsPerformance = determinePerformanceLevel(
    dailyStats?.steps ?? 0,
    10000
  );

  const sleepDurationPerformance = determinePerformanceLevel(
    sleepInsights.sleepDuration?.value ?? 0,
    sleepInsights.sleepDuration?.goal ?? 0
  );

  const caloriesBurnedPerformance = determinePerformanceLevel(
    dailyStats?.activeCalories ?? 0,
    500
  );

  useEffect(() => {
    const checkIsApiKeyAuthorised = async () => {
      setIsApiKeyAuthorised(
        !!activityHealthData?.isAuthorised &&
          !!readinessHealthData?.isAuthorised &&
          !!sleepHealthData?.isAuthorised
      );
    };

    const checkSensorStatus = async () => {
      const sahhaService = SahhaService.getInstance();
      const status = await sahhaService.getSahhaSensorStatus();
      setSensorStatus(status === "enabled");
    };

    const checkAuthStatus = async () => {
      const status = await Sahha.isAuthenticated();
      setAuthStatus(status.success);
    };

    checkSensorStatus();
    checkIsApiKeyAuthorised();
    checkDeviceIsMobileApp();
    checkAuthStatus();
  }, [activityHealthData, readinessHealthData, sleepHealthData]);

  const displayActivityCards = authStatus && isApiKeyAuthorised && sensorStatus;
  useEffect(() => {
    const fetchDailyStats = async () => {
      if (displayActivityCards) {
        const sahhaService = SahhaService.getInstance();
        const steps = await sahhaService.getStepStats();
        const activeCalories = await sahhaService.getActiveCaloriesStats();

        setDailyStats({
          steps: steps.value,
          activeCalories: activeCalories.value,
        });
      }
    };

    fetchDailyStats();
  }, [displayActivityCards]);

  const handleShowActivityDetails = () => {
    if (!displayActivityCards) {
      return;
    }
    setShowStepsDetail(true);
  };

  const handleShowSleepDetails = () => {
    if (!displayActivityCards) {
      return;
    }
    setShowSleepDetail(true);
  };

  const handleShowReadinessDetails = () => {
    if (!displayActivityCards) {
      return;
    }
    setShowReadinessDetail(true);
  };

  const handleContinueWorkout = () => {
    if (nextWorkout) {
      dispatch(setCurrentWorkout(nextWorkout as AnyType));
      navigate(`${Routes.workouts.url}/${nextWorkout.id}`);
    }
  };

  const isApiLoading =
    sleepHealthDataLoading ||
    readinessHealthDataLoading ||
    activityHealthDataLoading;

  const currentDate = dayjs().format("dddd, MMMM D");
  const getTimeBasedGreeting = () => {
    const hour = dayjs().local().hour();
    if (hour >= 5 && hour < 12) return { text: "morning", emoji: "🌞" };
    if (hour >= 12 && hour < 17) return { text: "afternoon", emoji: "🌤️" };
    return { text: "evening", emoji: "🌙" };
  };

  const { text: greetingTime, emoji } = getTimeBasedGreeting();
  const greeting = user?.firstName
    ? `Good ${greetingTime} ${user.firstName} ${emoji} `
    : `Good ${greetingTime}!`;

  if (user?.userRole !== UserRole.Athlete) {
    return <Skeleton variant="rectangular" height={118} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "auto",
        pb: "80px",
      }}
    >
      {/* Gradient Header */}
      <Box
        sx={{
          width: "100%",
          color: "#fff",
          padding: "24px 16px",
          background: `linear-gradient(
              126.67deg,
              #8B44AD 0%,
              #6C5CE7 50.52%,
              #4834D4 100%
            )`,
        }}
      >
        <Box
          sx={{
            height: 40,
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
            mb: 3,
            pt: 6.5,
          }}
        >
          <IconButton
            aria-label="timeline"
            onClick={() => navigate(Routes.athleteTimeline.url)}
            sx={{
              padding: 0,
              backgroundColor: "transparent",
            }}
          >
            <Box
              sx={{
                backgroundColor: "rgba(255,255,255,0.15)",
                borderRadius: "50%",
                width: 40,
                height: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
              }}
            >
              <OutlinedIcon iconName="question_answer" />
            </Box>
          </IconButton>
          <IconButton
            aria-label="nutrition"
            onClick={() => navigate(Routes.athleteBookings.url)}
            sx={{
              padding: 0,
              backgroundColor: "transparent",
            }}
          >
            <Box
              sx={{
                backgroundColor: "rgba(255,255,255,0.15)",
                borderRadius: "50%",
                width: 40,
                height: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
              }}
            >
              <OutlinedIcon iconName="event" />
            </Box>
          </IconButton>

          <IconButton
            aria-label="profile"
            onClick={handleNavigateToProfileUrl}
            sx={{
              padding: 0,
            }}
          >
            {user?.profileImageUrl ? (
              <IdenticonAvatar
                sizeValue={40}
                seedValue={user.id?.toString()}
                profileImageUrl={user?.profileImageUrl || ""}
              />
            ) : (
              <Box
                sx={{
                  backgroundColor: "transparent",
                  borderRadius: "60%",
                  padding: 1,
                  border: "2px solid white",
                  color: "white",
                }}
              >
                <PersonIcon fontSize="large" />
              </Box>
            )}
          </IconButton>
        </Box>
        <Typography variant="body2" sx={{ opacity: 0.9, mb: 1 }}>
          {currentDate}
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {greeting}
        </Typography>

        {/* Current Workout Card */}
        {isLoading ? (
          <Box
            sx={{
              mt: 4,
              p: 2,
              borderRadius: 2,
              backgroundColor: "rgba(255,255,255,0.15)",
              backdropFilter: "blur(8px)",
            }}
          >
            <Skeleton
              variant="text"
              width={140}
              height={32}
              sx={{ bgcolor: "rgba(255,255,255,0.2)" }}
            />
            <Skeleton
              variant="text"
              width={200}
              height={24}
              sx={{ mb: 2, bgcolor: "rgba(255,255,255,0.2)" }}
            />
            <Skeleton
              variant="rectangular"
              height={36}
              sx={{ borderRadius: 2, bgcolor: "rgba(255,255,255,0.2)" }}
            />
          </Box>
        ) : nextWorkout ? (
          <Box
            sx={{
              mt: 4,
              p: 2,
              borderRadius: 2,
              backgroundColor: "rgba(255,255,255,0.15)",
              backdropFilter: "blur(8px)",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold", color: "#fff" }}>
              Next workout
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, color: "#fff" }}>
              Week {nextWorkout.weekNumber} - Session {nextWorkout.dayNumber}
            </Typography>

            <Button
              variant="contained"
              onClick={handleContinueWorkout}
              fullWidth
              sx={{
                borderRadius: 2,
                textTransform: "none",
                backgroundColor: "#fff",
                color: "#241465",
                "&:hover": {
                  backgroundColor: "#f1f1f1",
                },
              }}
            >
              Start workout
            </Button>
          </Box>
        ) : null}
      </Box>

      {/* Health Cards */}
      {!showStepsDetail && !showSleepDetail && !showReadinessDetail && (
        <>
          {displayActivityCards && !isApiLoading && (
            <>
              <Grid container spacing={2} sx={{ mt: 2, px: 2, mb: 2 }}>
                <Grid item xs={6}>
                  <Box onClick={handleShowActivityDetails}>
                    <ActivityCard
                      title="Steps"
                      value={dailyStats.steps}
                      target={10000}
                      gradient={getGradientForScore(todayStepsPerformance)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box onClick={handleShowActivityDetails}>
                    <ActivityCard
                      title="Calories burned"
                      value={dailyStats.activeCalories}
                      target={500}
                      unit="kcal"
                      gradient={getGradientForScore(caloriesBurnedPerformance)}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ px: 2 }}>
                {!sleepHealthDataLoading &&
                  (sleepHealthData?.healthScores?.length ?? 0) > 0 &&
                  sleepInsights.hasRecentData && (
                    <Grid item xs={6}>
                      <Box onClick={handleShowSleepDetails}>
                        <SleepCard
                          sleepDuration={
                            sleepInsights?.sleepDuration?.value ?? 0
                          }
                          sleepDurationGoal={
                            sleepInsights?.sleepDuration?.goal ?? 0
                          }
                          gradient={getGradientForScore(
                            sleepDurationPerformance
                          )}
                        />
                      </Box>
                    </Grid>
                  )}
                {!readinessHealthDataLoading &&
                  (readinessHealthData?.healthScores?.length ?? 0) > 0 &&
                  readinessInsights.hasRecentData && (
                    <Grid item xs={6}>
                      <Box onClick={handleShowReadinessDetails}>
                        <ReadinessCard
                          averageRestingHeartRate={
                            readinessInsights.averageRestingHeartRate ?? 0
                          }
                        />
                      </Box>
                    </Grid>
                  )}
              </Grid>
            </>
          )}

          {isApiLoading && (
            <Grid item xs={12}>
              <Skeleton variant="rectangular" height={118} />
            </Grid>
          )}

          {(!displayActivityCards || !isApiKeyAuthorised) && !isApiLoading && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                mb: 1,
                mt: 2,
                p: 3,
                mx: 2,
                borderRadius: 2,
                backdropFilter: "blur(8px)",
              }}
            >
              <Box
                component="img"
                src={getGraphicSvg("thinking")}
                alt="Thinking"
                sx={{ height: "80px", mb: 2, opacity: 0.9 }}
              />
              <Typography
                variant="body1"
                sx={{ mb: 2, opacity: 0.9, fontSize: "0.9rem" }}
              >
                {isMobileApp ? MOBILE_APP_MESSAGE : WEB_APP_MESSAGE}
              </Typography>
              {isMobileApp && (
                <Button
                  variant="contained"
                  onClick={() => setShowHealthPermissions(true)}
                  startIcon={<Icon>settings</Icon>}
                  sx={{
                    textTransform: "none",
                    backgroundColor: "rgba(255,255,255,0.15)",
                    fontSize: "0.8rem",
                    "&:hover": {
                      backgroundColor: "rgba(255,255,255,0.25)",
                    },
                  }}
                >
                  Configure Health Permissions
                </Button>
              )}
            </Box>
          )}
        </>
      )}

      {/* Modals */}
      <StepsDetailCard
        insights={activityInsights}
        open={showStepsDetail}
        onClose={() => setShowStepsDetail(false)}
        todayStats={dailyStats}
      />
      <SleepDetailsCard
        sleepInsights={sleepInsights}
        open={showSleepDetail}
        onClose={() => setShowSleepDetail(false)}
      />
      <ReadinessDetailsCard
        readinessInsights={readinessInsights}
        open={showReadinessDetail}
        onClose={() => setShowReadinessDetail(false)}
      />
      <HealthPermissionsDialog
        isMobileApp={isMobileApp}
        open={showHealthPermissions}
        onClose={() => setShowHealthPermissions(false)}
      />
    </Box>
  );
};

export { AthleteDashboard };
