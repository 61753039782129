import { Box } from "@mui/material";
import { FC } from "react";
import { WorkoutPrograms } from "pages/Programs/AthletePrograms/ui/WorkoutPrograms";
import { Workouts } from "./ui";

const AthleteWorkouts: FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "auto",
        paddingBottom: "80px",
      }}
    >
      <Workouts />
      <WorkoutPrograms />
    </Box>
  );
};

export { AthleteWorkouts };
