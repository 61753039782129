import { Box, Button, Dialog, Icon, Slide, Typography } from "@mui/material";
import React, { FC } from "react";
import { TrainingInsightsLineChartContainerForAverages } from "pages/TrainingInsights/TrainingInsightsLineChartContainerForAverages";
import { Colors } from "shared/themes";
import { TransitionProps } from "@mui/material/transitions";

interface WorkoutTrendGraphProps {
  open: boolean;
  onClose: () => void;
  exerciseId: number;
  exerciseName: string;
  userId: number;
  isAthleteView: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const WorkoutTrendGraph: FC<WorkoutTrendGraphProps> = ({
  open,
  onClose,
  exerciseId,
  exerciseName,
  userId,
  isAthleteView,
}) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          pt: 8,
          pb: 3,
          pl: 3,
          pr: 3,
          overflow: "auto",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
          <Button
            type="button"
            onClick={onClose}
            sx={{
              minWidth: "auto",
              padding: 0,
              color: "primary.main",
              "&:hover": {
                background: "none",
              },
              textTransform: "none",
            }}
          >
            <Icon
              sx={{
                fontSize: "16px !important",
                width: "16px",
                height: "16px",
              }}
            >
              arrow_back_ios
            </Icon>
            <Typography
              sx={{
                fontSize: "14px",
                ml: 0.5,
                color: "primary.main",
                fontWeight: 500,
              }}
            >
              Return
            </Typography>
          </Button>
        </Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          History for {exerciseName}
        </Typography>
        <TrainingInsightsLineChartContainerForAverages
          exercise={{
            exerciseId,
            exerciseName,
          }}
          userId={userId}
          isAthleteView={isAthleteView}
        />
      </Box>
    </Dialog>
  );
};
