import {
  Dialog,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  Icon,
  Slide,
} from "@mui/material";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SnackbarAlert } from "components/SnackbarAlert/SnackbarAlert";
import {
  CombinedData,
  useSetAlternativeExerciseMutation,
  SubstituteExerciseParams,
  exercisesApi,
} from "shared/api";
import { useTypedDispatch } from "shared/stores";
import { TransitionProps } from "@mui/material/transitions";
import { Colors } from "../../../../shared/themes";

interface SwapExerciseModalProps {
  swapExerciseModal: boolean;
  setSwapExerciseModal: (value: boolean) => void;
  exercise: CombinedData;
  workoutItemId: number;
  exerciseName: string;
  setExerciseName: (value: string) => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SwapExerciseModal: FC<SwapExerciseModalProps> = ({
  swapExerciseModal,
  setSwapExerciseModal,
  exercise,
  workoutItemId,
  exerciseName,
  setExerciseName,
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<string[]>([]);
  const [fetchErrorMessage, setFetchErrorMessage] = useState<string>("");
  const dispatch = useTypedDispatch();
  const getExerciseSubstitute =
    exercisesApi.endpoints.getSubstituteExercisesByExerciseId.initiate(
      exercise.exerciseId
    );

  async function callGetExerciseSubstitutes() {
    try {
      const response = await dispatch(getExerciseSubstitute);
      setData(response.data ?? []);
    } catch (error: any) {
      setFetchErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (swapExerciseModal) callGetExerciseSubstitutes();
  }, [exercise.exerciseId, swapExerciseModal]);

  const [selectedAlternativeExercise, setSelectedAlternativeExercise] =
    useState("");

  const [otherSelected, setOtherSelected] = useState(false);

  const [setAlternativeExercise] = useSetAlternativeExerciseMutation();

  const [errorMessage, setErrorMessage] = useState("");

  const [successMessage, setSuccessMessage] = useState("");

  const [snackbarAlertOpen, setSnackbarAlertOpen] = useState(false);

  const [saving, setSaving] = useState(false);

  const handleSnackbarAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarAlertOpen(false);
  };

  const handleSwapExercise = async () => {
    if (
      !selectedAlternativeExercise ||
      !workoutItemId ||
      !exercise.exerciseId
    ) {
      return;
    }

    try {
      setSaving(true);
      setSuccessMessage("");
      setErrorMessage("");
      setFetchErrorMessage("");

      const alternativeExercise = await setAlternativeExercise({
        workoutItemId,
        existingExerciseId: exercise.exerciseId,
        substitutionExerciseName: selectedAlternativeExercise,
      } as SubstituteExerciseParams).unwrap();

      setExerciseName(alternativeExercise.name);

      setSuccessMessage(t("item-modal.swapped-exercise-success"));
      setErrorMessage("");
    } catch (error: any) {
      setSuccessMessage("");
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setSnackbarAlertOpen(true);
      setSaving(false);
    }
  };

  return (
    <Dialog
      fullScreen
      open={swapExerciseModal}
      onClose={() => setSwapExerciseModal(false)}
      TransitionComponent={Transition}
    >
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          pt: 8,
          pb: 3,
          pl: 3,
          pr: 3,
          overflow: "auto",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
          <Button
            type="button"
            onClick={() => setSwapExerciseModal(false)}
            sx={{
              minWidth: "auto",
              padding: 0,
              color: "primary.main",
              "&:hover": {
                background: "none",
              },
              textTransform: "none",
            }}
          >
            <Icon
              sx={{
                fontSize: "16px !important",
                width: "16px",
                height: "16px",
              }}
            >
              arrow_back_ios
            </Icon>
            <Typography
              sx={{
                fontSize: "14px",
                ml: 0.5,
                color: "primary.main",
                fontWeight: 500,
              }}
            >
              Return
            </Typography>
          </Button>
        </Box>
        {!isLoading ? (
          <>
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "20px",
                lineHeight: "24px",
                color: `${Colors.oxford[1100]}`,
                textTransform: "none",
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              Select an alternative exercise for {exerciseName}
            </Typography>
            <Box>
              <FormControl>
                <RadioGroup>
                  {data?.map((exercise) => (
                    <FormControlLabel
                      value={exercise}
                      control={<Radio />}
                      label={exercise}
                      key={exercise}
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "19px",
                        color: `${Colors.oxford[1100]}`,
                        textTransform: "none",
                      }}
                      disabled={saving}
                      onClick={() => {
                        setSelectedAlternativeExercise(exercise);
                        setOtherSelected(false);
                      }}
                    />
                  ))}
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: `${Colors.oxford[1100]}`,
                      textTransform: "none",
                    }}
                    disabled={saving}
                    onClick={() => {
                      setSelectedAlternativeExercise("");
                      setOtherSelected(true);
                    }}
                  />
                  {otherSelected && (
                    <TextField
                      label="Other"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        marginBottom: "10px",
                      }}
                      value={selectedAlternativeExercise}
                      onChange={(e) => {
                        setSelectedAlternativeExercise(e.target.value);
                      }}
                    />
                  )}
                </RadioGroup>
              </FormControl>
            </Box>
            {saving && (
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: `${Colors.oxford[1100]}`,
                  textTransform: "none",
                  marginBottom: "10px",
                  textAlign: "center",
                }}
              >
                Swapping exercise...
              </Typography>
            )}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                paddingTop: "14px",
              }}
            >
              <LoadingButton
                onClick={handleSwapExercise}
                variant="contained"
                color="primary"
                sx={{
                  width: "45%",
                  height: "42px",
                  fontWeight: "500",
                  fontSize: "16px",
                  fontFamily: "Inter",
                  textTransform: "none",
                }}
                disabled={!selectedAlternativeExercise}
                loading={saving}
              >
                Swap
              </LoadingButton>
              <LoadingButton
                onClick={() => {
                  setOtherSelected(false);
                  setSwapExerciseModal(false);
                }}
                variant="outlined"
                color="primary"
                sx={{
                  width: "45%",
                  height: "42px",
                  fontWeight: "500",
                  fontSize: "16px",
                  fontFamily: "Inter",
                  color: `${Colors.blue[1200]}`,
                  textTransform: "none",
                }}
                loading={saving}
              >
                Cancel
              </LoadingButton>
            </Box>
            {errorMessage && (
              <SnackbarAlert
                isOpen={snackbarAlertOpen}
                onClose={handleSnackbarAlertClose}
                severity="error"
                message={errorMessage}
              />
            )}
            {successMessage && (
              <SnackbarAlert
                isOpen={snackbarAlertOpen}
                onClose={handleSnackbarAlertClose}
                severity="success"
                message={successMessage}
              />
            )}
          </>
        ) : (
          <Typography
            variant="h5"
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "24px",
              color: `${Colors.oxford[1100]}`,
              textTransform: "none",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            Loading...
          </Typography>
        )}
        {fetchErrorMessage && (
          <SnackbarAlert
            isOpen={snackbarAlertOpen}
            onClose={handleSnackbarAlertClose}
            severity="error"
            message={fetchErrorMessage}
          />
        )}
      </Box>
    </Dialog>
  );
};

export { SwapExerciseModal };
