import React from "react";
import {
  Box,
  Typography,
  Grid,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { ActivityCard } from "./ActivityCard";
import { determinePerformanceLevel, getGradientForScore } from "./formatters";
import { SleepCard } from "./SleepCard";
import { InsightDataItem } from "../AdminDashboard/utils/types";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SleepDetailsCardProps {
  sleepInsights: {
    overallScore: number;
    overallState: string;
    sleepDuration: InsightDataItem | null;
    sleepRegularity: InsightDataItem | null;
    sleepContinuity: InsightDataItem | null;
    sleepDebt: InsightDataItem | null;
  };
  open: boolean;
  onClose: () => void;
}

export const SleepDetailsCard: React.FC<SleepDetailsCardProps> = ({
  sleepInsights,
  open,
  onClose,
}) => {
  const overallScorePerformance = determinePerformanceLevel(
    sleepInsights.overallScore,
    1
  );
  const sleepDurationPerformance = determinePerformanceLevel(
    sleepInsights.sleepDuration?.value ?? 0,
    sleepInsights.sleepDuration?.goal ?? 0
  );
  const sleepRegularityPerformance = determinePerformanceLevel(
    sleepInsights.sleepRegularity?.score ?? 0,
    1
  );
  const sleepContinuityPerformance = determinePerformanceLevel(
    sleepInsights.sleepContinuity?.score ?? 0,
    10
  );
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          pt: 6,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
            Sleep Details
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle2" sx={{ color: "text.secondary", mt: 1 }}>
          Here&apos;s your daily sleep summary:
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={6}>
            <SleepCard
              sleepDuration={sleepInsights?.sleepDuration?.value ?? 0}
              sleepDurationGoal={sleepInsights?.sleepDuration?.goal ?? 0}
              gradient={getGradientForScore(sleepDurationPerformance)}
            />
          </Grid>
          <Grid item xs={6}>
            <ActivityCard
              title="Overall Sleep Score"
              value={sleepInsights.overallScore}
              target={1}
              gradient={getGradientForScore(overallScorePerformance)}
              overrideText={sleepInsights.overallState}
            />
          </Grid>
          <Grid item xs={6}>
            <ActivityCard
              title="Sleep Regularity"
              value={sleepInsights.sleepRegularity?.value ?? 0}
              target={sleepInsights.sleepRegularity?.goal ?? 0}
              gradient={getGradientForScore(sleepRegularityPerformance)}
              overrideText={sleepInsights.sleepRegularity?.state ?? ""}
            />
          </Grid>
          <Grid item xs={6}>
            <ActivityCard
              title="Sleep Continuity"
              value={sleepInsights.sleepContinuity?.value ?? 0}
              target={sleepInsights.sleepContinuity?.goal ?? 0}
              gradient={getGradientForScore(sleepContinuityPerformance)}
              overrideText={sleepInsights.sleepContinuity?.state ?? ""}
            />
          </Grid>
          <Grid item xs={6}>
            <ActivityCard
              title="Sleep Debt"
              value={sleepInsights.sleepDebt?.value ?? 0}
              target={sleepInsights.sleepDebt?.goal ?? 0}
              unit={sleepInsights.sleepDebt?.unit ?? ""}
              gradient={getGradientForScore("poor")}
            />
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
