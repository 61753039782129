import { Box, TextField, Typography } from "@mui/material";
import { FC } from "react";
import { CombinedData } from "shared/api";
import { Colors } from "shared/themes";

type UnilateralInputScreenProps = {
  values: any;
  handleChange: any;
  handleBlur: any;
  exercise: CombinedData;
};
// eslint-disable-next-line
const UnilateralInputScreen: FC<UnilateralInputScreenProps> = ({
  values,
  handleChange,
  handleBlur,
  exercise,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        gap: "4px",
        flex: "none",
        order: 1,
        flexGrow: 0,
        borderRadius: "8px",
        background: "white",
        p: 1.5,
      }}
    >
      <Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            mb: "10px",
            "&::before, &::after": {
              content: '""',
              flex: 1,
              borderBottom: `1px solid ${Colors.oxford[1100]}`,
              opacity: 0.12,
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              letterSpacing: "0.1em",
              textTransform: "uppercase",
              px: 2,
            }}
          >
            LEFT SIDE
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            padding: "0px",
            mb: "12px",
          }}
        >
          <TextField
            sx={{
              width: "100%",
              height: "40px",
              background: "white",
              "& input[type=number]": {
                "-moz-appearance": "textfield",
                height: "40px",
                padding: "8px 14px",
                fontSize: "14px",
              },
              "& input": {
                height: "40px",
                padding: "8px 14px",
                fontSize: "14px",
              },
              "& .MuiOutlinedInput-root": {
                height: "40px",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                transform: "translate(14px, 8px) scale(1)",
                "&.Mui-focused, &.MuiFormLabel-filled": {
                  transform: "translate(14px, -9px) scale(0.75)",
                },
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{
              step: "any",
              inputMode: "decimal",
            }}
            value={values.completedWeightLeftSide}
            label={
              exercise.workoutItemWeightUnit === "Lbs"
                ? "Weight (Lbs)"
                : "Weight (Kg)"
            }
            onBlur={handleBlur("completedWeightLeftSide")}
            onChange={handleChange("completedWeightLeftSide")}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px",
            gap: "12px",
            flex: "none",
            order: 1,
            flexGrow: 0,
          }}
        >
          <TextField
            sx={{
              width: "50%",
              height: "40px",
              background: "white",
              "& input[type=number]": {
                "-moz-appearance": "textfield",
                height: "40px",
                padding: "8px 14px",
                fontSize: "14px",
              },
              "& input": {
                height: "40px",
                padding: "8px 14px",
                fontSize: "14px",
              },
              "& .MuiOutlinedInput-root": {
                height: "40px",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                transform: "translate(14px, 8px) scale(1)",
                "&.Mui-focused, &.MuiFormLabel-filled": {
                  transform: "translate(14px, -9px) scale(0.75)",
                },
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{
              step: "any",
              inputMode: "decimal",
            }}
            value={values.completedRepsLeftSide}
            label="Reps"
            onBlur={handleBlur("completedRepsLeftSide")}
            onChange={handleChange("completedRepsLeftSide")}
          />
          <TextField
            sx={{
              width: "50%",
              height: "40px",
              background: "white",
              "& input[type=number]": {
                "-moz-appearance": "textfield",
                height: "40px",
                padding: "8px 14px",
                fontSize: "14px",
              },
              "& input": {
                height: "40px",
                padding: "8px 14px",
                fontSize: "14px",
              },
              "& .MuiOutlinedInput-root": {
                height: "40px",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                transform: "translate(14px, 8px) scale(1)",
                "&.Mui-focused, &.MuiFormLabel-filled": {
                  transform: "translate(14px, -9px) scale(0.75)",
                },
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{
              step: "any",
              inputMode: "decimal",
            }}
            value={values.completedIntensityLeftSide}
            label={exercise.workoutItemType === "RIR" ? "RIR" : "RPE"}
            onBlur={handleBlur("completedIntensityLeftSide")}
            onChange={handleChange("completedIntensityLeftSide")}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          mb: "10px",
          mt: "10px",
          "&::before, &::after": {
            content: '""',
            flex: 1,
            borderBottom: `1px solid ${Colors.oxford[1100]}`,
            opacity: 0.12,
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            letterSpacing: "0.1em",
            textTransform: "uppercase",
            px: 2,
          }}
        >
          RIGHT SIDE
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: "0px",
            mb: "12px",
          }}
        >
          <TextField
            sx={{
              width: "100%",
              height: "40px",
              background: "white",
              "& input[type=number]": {
                "-moz-appearance": "textfield",
                height: "40px",
                padding: "8px 14px",
                fontSize: "14px",
              },
              "& input": {
                height: "40px",
                padding: "8px 14px",
                fontSize: "14px",
              },
              "& .MuiOutlinedInput-root": {
                height: "40px",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                transform: "translate(14px, 8px) scale(1)",
                "&.Mui-focused, &.MuiFormLabel-filled": {
                  transform: "translate(14px, -9px) scale(0.75)",
                },
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{
              step: "any",
              inputMode: "decimal",
            }}
            value={values.completedWeightRightSide}
            label={
              exercise.workoutItemWeightUnit === "Lbs"
                ? "Weight (Lbs)"
                : "Weight (Kg)"
            }
            onBlur={handleBlur("completedWeightRightSide")}
            onChange={handleChange("completedWeightRightSide")}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px",
            gap: "12px",
            flex: "none",
            order: 1,
            flexGrow: 0,
          }}
        >
          <TextField
            sx={{
              width: "50%",
              height: "40px",
              background: "white",
              "& input[type=number]": {
                "-moz-appearance": "textfield",
                height: "40px",
                padding: "8px 14px",
                fontSize: "14px",
              },
              "& input": {
                height: "40px",
                padding: "8px 14px",
                fontSize: "14px",
              },
              "& .MuiOutlinedInput-root": {
                height: "40px",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                transform: "translate(14px, 8px) scale(1)",
                "&.Mui-focused, &.MuiFormLabel-filled": {
                  transform: "translate(14px, -9px) scale(0.75)",
                },
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{
              step: "any",
              inputMode: "decimal",
            }}
            value={values.completedRepsRightSide}
            label="Reps"
            onBlur={handleBlur("completedRepsRightSide")}
            onChange={handleChange("completedRepsRightSide")}
          />
          <TextField
            sx={{
              width: "50%",
              height: "40px",
              background: "white",
              "& input[type=number]": {
                "-moz-appearance": "textfield",
                height: "40px",
                padding: "8px 14px",
                fontSize: "14px",
              },
              "& input": {
                height: "40px",
                padding: "8px 14px",
                fontSize: "14px",
              },
              "& .MuiOutlinedInput-root": {
                height: "40px",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                transform: "translate(14px, 8px) scale(1)",
                "&.Mui-focused, &.MuiFormLabel-filled": {
                  transform: "translate(14px, -9px) scale(0.75)",
                },
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{
              step: "any",
              inputMode: "decimal",
            }}
            value={values.completedIntensityRightSide}
            label={exercise.workoutItemType === "RIR" ? "RIR" : "RPE"}
            onBlur={handleBlur("completedIntensityRightSide")}
            onChange={handleChange("completedIntensityRightSide")}
          />
        </Box>
      </Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <hr
          style={{
            border: "none",
            height: "1px",
            background: `${Colors.gray[500]}`,
            width: "100%",
          }}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: "0px",
          mt: "12px",
        }}
      >
        <TextField
          sx={{
            width: "100%",
            height: "40px",
            background: "white",
            "& input[type=number]": {
              "-moz-appearance": "textfield",
              height: "40px",
              padding: "8px 14px",
              fontSize: "14px",
            },
            "& input": {
              height: "40px",
              padding: "8px 14px",
              fontSize: "14px",
            },
            "& .MuiOutlinedInput-root": {
              height: "40px",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              transform: "translate(14px, 8px) scale(1)",
              "&.Mui-focused, &.MuiFormLabel-filled": {
                transform: "translate(14px, -9px) scale(0.75)",
              },
            },
          }}
          value={values.notes}
          label="Notes"
          onBlur={handleBlur("notes")}
          onChange={handleChange("notes")}
        />
      </Box>
    </Box>
  );
};

export { UnilateralInputScreen };
