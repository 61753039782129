/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Icon,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { LoadingButton } from "@mui/lab";
import SensorsIcon from "@mui/icons-material/Sensors";
import { getGraphicSvg } from "shared/themes";
import { Sahha } from "sahha-capacitor";
import SahhaService from "SahhaService";
import { useTypedDispatch, useTypedSelector } from "shared/stores";
import {
  useGetHealthScoresForUserQuery,
  useGetProfileTokenForUserQuery,
  useActivateProfileTokenMutation,
  healthScoresApi,
  UserRole,
} from "shared/api";
import { userSelectors } from "shared/stores/user";
import { OutlinedIcon } from "components/OutlinedIcon/OutlinedIcon";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface HealthPermissionsDialogProps {
  open: boolean;
  onClose: () => void;
  isMobileApp: boolean;
}

export const HealthPermissionsDialog: React.FC<
  HealthPermissionsDialogProps
> = ({ open, onClose, isMobileApp }) => {
  const dispatch = useTypedDispatch();
  const user = useTypedSelector(userSelectors.all);

  // State
  const [isApiKeyAuthorised, setIsApiKeyAuthorised] = useState(false);
  const [isDeviceAuthorised, setIsDeviceAuthorised] = useState(false);
  const [sensorStatus, setSensorStatus] = useState<string>("pending");
  const [isActivatingApiKey, setIsActivatingApiKey] = useState(false);
  const [isAuthorisingDevice, setIsAuthorisingDevice] = useState(false);
  const [isEnablingSensors, setIsEnablingSensors] = useState(false);

  // Queries
  const { data: activityHealthData } = useGetHealthScoresForUserQuery(
    { type: "activity", userId: user?.id! },
    { skip: !user?.id || !isMobileApp }
  );
  const { data: readinessHealthData } = useGetHealthScoresForUserQuery(
    { type: "readiness", userId: user?.id! },
    { skip: !user?.id || !isMobileApp }
  );
  const { data: sleepHealthData } = useGetHealthScoresForUserQuery(
    { type: "sleep", userId: user?.id! },
    { skip: !user?.id || !isMobileApp }
  );
  const { data: profileData } = useGetProfileTokenForUserQuery(
    {},
    { skip: !user?.id || user?.userRole !== UserRole.Athlete || !isMobileApp }
  );

  // Mutations
  const [activateProfileToken] = useActivateProfileTokenMutation();

  // Effects
  useEffect(() => {
    const checkStatuses = async () => {
      // Check API key authorization
      setIsApiKeyAuthorised(
        !!activityHealthData?.isAuthorised &&
          !!readinessHealthData?.isAuthorised &&
          !!sleepHealthData?.isAuthorised
      );

      // Check device authorization
      const authStatus = await Sahha.isAuthenticated();
      setIsDeviceAuthorised(authStatus.success);

      // Check sensor status
      const sahhaService = SahhaService.getInstance();
      const status = await sahhaService.getSahhaSensorStatus();
      setSensorStatus(status);
    };

    if (open) {
      checkStatuses();
    }
  }, [open, activityHealthData, readinessHealthData, sleepHealthData]);

  // Handlers
  const handleActivateApiKey = async () => {
    try {
      setIsActivatingApiKey(true);
      await activateProfileToken().unwrap();
    } catch (error) {
      console.error("Failed to activate API key:", error);
    } finally {
      setIsActivatingApiKey(false);
    }
  };

  const handleAuthoriseDevice = async () => {
    try {
      setIsAuthorisingDevice(true);
      const sahhaService = SahhaService.getInstance();
      if (profileData?.profileToken && profileData?.refreshToken) {
        await sahhaService.login(
          profileData.profileToken,
          profileData.refreshToken
        );
        const authStatus = await Sahha.isAuthenticated();
        setIsDeviceAuthorised(authStatus.success);
      }
    } catch (error) {
      console.error("Failed to authorize device:", error);
    } finally {
      setIsAuthorisingDevice(false);
    }
  };

  const handleEnableSensors = async () => {
    try {
      setIsEnablingSensors(true);
      const sahhaService = SahhaService.getInstance();

      // Check if authenticated first
      const authStatus = await Sahha.isAuthenticated();
      if (
        !authStatus.success &&
        profileData?.profileToken &&
        profileData?.refreshToken
      ) {
        // If not authenticated, authenticate with tokens
        await sahhaService.login(
          profileData.profileToken,
          profileData.refreshToken
        );
      }

      // Now try to enable sensors
      const enabled = await sahhaService.enableSahhaSensors();
      if (enabled) {
        const newStatus = await sahhaService.getSahhaSensorStatus();
        setSensorStatus(newStatus);
        dispatch(healthScoresApi.util.invalidateTags(["HealthScores"]));
      } else {
        // If enabling fails, open app settings
        await SahhaService.openAppSettings();
      }
    } catch (error) {
      console.error("Failed to enable sensors:", error);
    } finally {
      setIsEnablingSensors(false);
    }
  };

  const completed =
    isApiKeyAuthorised && isDeviceAuthorised && sensorStatus === "enabled";

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          pt: 6,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
            Health Permissions
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
          }}
        >
          <Box
            component="img"
            src={getGraphicSvg("thinking")}
            alt="Thinking"
            sx={{ height: "110px", mb: 2 }}
          />
          <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
            To access health insights, we need your permission to collect and
            analyze your health data.
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
              maxWidth: 400,
            }}
          >
            {/* API Key Authorization */}
            <LoadingButton
              variant="contained"
              size="large"
              onClick={handleActivateApiKey}
              startIcon={<Icon>key</Icon>}
              endIcon={isApiKeyAuthorised ? <Icon>done</Icon> : null}
              loading={isActivatingApiKey}
              disabled={isApiKeyAuthorised}
              color={isApiKeyAuthorised ? "success" : "primary"}
              sx={{ textTransform: "none" }}
            >
              {isApiKeyAuthorised ? "Profile Created" : "Create Profile"}
            </LoadingButton>

            {/* Device Authorization */}
            <LoadingButton
              variant="contained"
              size="large"
              onClick={handleAuthoriseDevice}
              startIcon={<Icon>phone_iphone</Icon>}
              endIcon={isDeviceAuthorised ? <Icon>done</Icon> : null}
              loading={isAuthorisingDevice}
              disabled={isDeviceAuthorised || !isApiKeyAuthorised}
              color={isDeviceAuthorised ? "success" : "primary"}
              sx={{ textTransform: "none" }}
            >
              {isDeviceAuthorised ? "Device Authorized" : "Authorize Device"}
            </LoadingButton>

            {/* Sensor Status */}
            <LoadingButton
              variant="contained"
              size="large"
              onClick={handleEnableSensors}
              startIcon={<SensorsIcon />}
              loading={isEnablingSensors}
              endIcon={sensorStatus === "enabled" ? <Icon>done</Icon> : null}
              disabled={
                sensorStatus === "enabled" ||
                !isDeviceAuthorised ||
                !isApiKeyAuthorised
              }
              color={sensorStatus === "enabled" ? "success" : "primary"}
              sx={{ textTransform: "none" }}
            >
              {sensorStatus === "enabled"
                ? "Sensors Enabled"
                : sensorStatus === "pending"
                ? "Sensors Pending"
                : sensorStatus === "unavailable"
                ? "Sensors Unavailable"
                : "Enable Sensors"}
            </LoadingButton>
          </Box>

          {!completed && (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
              Complete all steps above to enable health tracking
            </Typography>
          )}

          {completed && (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
              Congratulations! You&apos;re all set to start tracking your health
              data.
              <OutlinedIcon iconName="done" />
            </Typography>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
