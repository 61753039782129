import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  CompletedExerciseHistory,
  ExerciseFrequency,
  GetCompletedHistoryParams,
  ProgressInsights,
  UserFeedbackSummary,
} from "./models";

export const trainingInsightsApi = createApi({
  baseQuery,
  reducerPath: "trainingInsightsApi",
  tagTypes: [
    "FrequentExercises",
    "PrescribedExerciseHistory",
    "CompletedExerciseHistory",
    "UserFeedbackSummary",
    "ProgressInsights",
  ],
  endpoints: (builder) => ({
    getMostFrequentPrescribedExercises: builder.query<
      ExerciseFrequency[],
      number
    >({
      query: (id) => ({
        url: `/user-history/${id}`,
        method: "GET",
      }),
      providesTags: ["FrequentExercises"],
    }),
    getCompletedHistoryByExerciseId: builder.query<
      CompletedExerciseHistory[],
      GetCompletedHistoryParams
    >({
      query: (params) => ({
        url: `/user-history/${params.userId}/completed/${params.exerciseId}`,
        method: "GET",
        params: {
          count: params.count,
        },
      }),
      providesTags: ["CompletedExerciseHistory"],
    }),
    getLatestUserFeedbackSummary: builder.query<UserFeedbackSummary, number>({
      query: (id) => ({
        url: `/user-feedback-summaries/latest?userId=${id}`,
        method: "GET",
      }),
      providesTags: ["UserFeedbackSummary"],
    }),
    getProgressInsights: builder.query<ProgressInsights, number>({
      query: (id) => ({
        url: `/user-history/${id}/progress-insights`,
        method: "GET",
      }),
      providesTags: ["ProgressInsights"],
    }),
  }),
});

export const {
  useGetMostFrequentPrescribedExercisesQuery,
  useGetCompletedHistoryByExerciseIdQuery,
  useGetProgressInsightsQuery,
} = trainingInsightsApi;
