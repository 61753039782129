/* eslint-disable no-nested-ternary */
import { Alert, Card, Collapse, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Colors, Images } from "shared/themes";
import { useGetWorkoutsQuery } from "shared/api";
import advancedFormat from "dayjs/plugin/advancedFormat";
import dayjs from "dayjs";
import { SessionCard } from "./SessionCard";
import { WorkoutPageSkeleton } from "./WorkoutPageSkeleton";
import { ActionButton } from "./ActionButton";
import { WorkoutList } from "./WorkoutList";

const Workouts: FC = () => {
  dayjs.extend(advancedFormat);
  const { data, isLoading, refetch } = useGetWorkoutsQuery();
  const [activeIndex, setActiveIndex] = useState<number | null>(1);

  const { t } = useTranslation();

  useEffect(() => {
    refetch();
  }, []);

  const workoutProgramWeeksFrequency =
    data?.curentWorkoutProgram?.weeksInProgram === 1
      ? "1 week"
      : `${data?.curentWorkoutProgram?.weeksInProgram} weeks`;

  const workoutProgramDaysFrequency =
    data?.curentWorkoutProgram?.daysPerWeek === 1
      ? "1 day per week"
      : `${data?.curentWorkoutProgram?.daysPerWeek} days per week`;

  const workoutProgramFrequencyText = `${workoutProgramWeeksFrequency}, ${workoutProgramDaysFrequency}`;

  return !isLoading ? (
    <Box sx={{ overflowY: "auto" }}>
      <Card
        sx={{
          width: "100%",
          position: "relative",
          borderRadius: 0,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
          pt: 8,
          pb: 3,
          pl: 3,
          pr: 3,
        }}
      >
        <Box>
          <Typography
            variant="h5"
            sx={{
              color: Colors.gray[100],
              fontWeight: 600,
              mb: 1,
            }}
          >
            {data?.curentWorkoutProgram?.name || "No program selected"}
          </Typography>

          {data?.curentWorkoutProgram &&
            !data?.curentWorkoutProgram?.invoiceIsOverdue && (
              <>
                <Typography
                  sx={{
                    color: Colors.gray[100],
                    mb: 1,
                  }}
                >
                  {workoutProgramFrequencyText}
                </Typography>

                <Typography
                  sx={{
                    color: Colors.gray[100],
                    fontSize: 14,
                  }}
                >
                  {dayjs(data?.curentWorkoutProgram?.startDate).format(
                    "Do MMMM YYYY"
                  )}
                </Typography>
              </>
            )}
        </Box>
      </Card>
      <Box
        sx={{
          padding: "9px 24px 23px 24px",
        }}
      >
        <Box sx={{ mb: 2 }}>
          {data?.curentWorkoutProgram?.invoiceIsOverdue && (
            <Alert
              variant="outlined"
              severity="error"
              sx={{ mb: "10px", alignItems: "center" }}
            >
              <Typography variant="h6">
                {t("athlete-dashboard.invoice-overdue")}
              </Typography>

              <Typography variant="body2">
                {t("athlete-dashboard.invoice-overdue-description")}
              </Typography>
            </Alert>
          )}

          {data?.curentWorkoutProgram?.invoiceDueDateTime &&
            !data?.curentWorkoutProgram?.invoiceIsOverdue &&
            data?.curentWorkoutProgram?.invoiceStatus === "open" && (
              <Alert
                variant="outlined"
                severity="warning"
                sx={{ mb: "10px", alignItems: "center" }}
              >
                <Typography variant="h6">
                  {t("athlete-dashboard.invoice-due-warning")}:{" "}
                  {dayjs(data?.curentWorkoutProgram?.invoiceDueDateTime).format(
                    "Do MMMM YYYY"
                  )}
                </Typography>

                <Typography variant="body2">
                  {t("athlete-dashboard.invoice-due-warning-description")}
                </Typography>
              </Alert>
            )}
        </Box>

        {data?.remainingWorkouts?.length ? (
          <>
            <Typography variant="h6">Next workout</Typography>
            <SessionCard
              item={data?.remainingWorkouts[0]}
              id={data?.remainingWorkouts[0].id}
              image={Images.CurrentSession}
              dayNumber={data?.remainingWorkouts[0].dayNumber}
              weekNumber={data?.remainingWorkouts[0].weekNumber}
              workoutStatus={data?.remainingWorkouts[0].workoutStatus}
            />
          </>
        ) : null}

        {data?.curentWorkoutProgram?.invoiceIsOverdue === false && (
          <>
            <ActionButton
              isActive={activeIndex === 1}
              title="Upcoming workouts"
              onPress={() => setActiveIndex(activeIndex === 1 ? null : 1)}
            />

            <Collapse in={activeIndex === 1} timeout="auto" unmountOnExit>
              {data?.remainingWorkouts?.length > 1 ? (
                <WorkoutList
                  workouts={data?.remainingWorkouts.slice(1) || []}
                />
              ) : (
                <Alert
                  variant="outlined"
                  severity="success"
                  sx={{ mt: 1, mb: "10px", alignItems: "center" }}
                >
                  <Typography variant="h6">
                    {t("athlete-dashboard.awesome")}
                  </Typography>

                  <Typography variant="body2">
                    {t("athlete-dashboard.description")}
                  </Typography>
                </Alert>
              )}
            </Collapse>
          </>
        )}
        {!!data?.completedWorkouts && data?.completedWorkouts?.length !== 0 && (
          <>
            <ActionButton
              isActive={activeIndex === 2}
              title="Completed workouts"
              onPress={() => setActiveIndex(activeIndex === 2 ? null : 2)}
            />

            <Collapse in={activeIndex === 2} timeout="auto" unmountOnExit>
              <WorkoutList workouts={data?.completedWorkouts} isCompleted />
            </Collapse>
          </>
        )}
      </Box>
    </Box>
  ) : (
    <WorkoutPageSkeleton />
  );
};

export { Workouts };
