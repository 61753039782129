import { FC } from "react";
import { BottomNavigation, BottomNavigationAction, Icon } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { userSelectors } from "shared/stores/user";
import { useTypedSelector } from "shared/stores";
import { Colors } from "../../../themes";
import { Routes } from "../../../routers";
import { navData } from "./constants";

const AthleteBottomNavigation: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = useTypedSelector(userSelectors.userId);

  return (
    <BottomNavigation
      value={location.pathname}
      onChange={(_, newValue) => {
        navigate(newValue);
      }}
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1200,
        height: "80px",
        borderTop: `1px solid ${Colors.gray[300]}`,
        "& .MuiBottomNavigationAction-root": {
          minWidth: 0,
          padding: 0,
        },
      }}
    >
      {navData.map((item) => {
        const value =
          // eslint-disable-next-line no-nested-ternary
          item.link === "profile"
            ? `${Routes.profile.url}/${userId}`
            : item.link;
        return (
          <BottomNavigationAction
            key={item.id}
            label={item.title}
            value={value}
            icon={<Icon className="material-icons-outlined">{item.icon}</Icon>}
            showLabel
            sx={{
              color: Colors.gray[900],
              "&.Mui-selected": {
                color: Colors.blue[1200],
                opacity: 1,
              },
              "& .MuiIcon-root": {
                marginBottom: 0,
              },
              paddingBottom: "10px !important",
            }}
          />
        );
      })}
    </BottomNavigation>
  );
};

export { AthleteBottomNavigation };
